import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import Typography from 'components/Typography';
import ActivityCard, { ActivityStatus } from 'components/ActivityCard';
import DashboardHeader from 'components/DashboardHeader';
import ActivityDetailsModal from 'components/ActivityDetailsModal';

import List, { KeyExtractor, ListRenderItem } from '@ra/components/List';

import useToggle from '@ra/hooks/useToggle';

import { Activity } from 'services/types';
import { useAppSelector } from 'hooks/store';
import { regionActivity } from 'store/slices/selectors/activity';

import styles from './styles.scss';

const keyExtractor: KeyExtractor<Activity> = (item) => item.id;

function groupByStatus<T>(collection: T[], key: keyof T) {
    const groups = collection.reduce((previous, current) => {
        if (!previous[current[key]]) {
            previous[current[key]] = [] as T[];
        }
        previous[current[key]].push(current);
        return previous;
    }, {} as any);
    return Object.keys(groups).map((status, index) => {
        return {
            id: index,
            status: status,
            data: groups[status]
        };
    });
}

const Activities: React.FC = () => {
    const activityData = useAppSelector((state) => regionActivity(state));

    const groupedData = groupByStatus(activityData, 'status');

    const [detailsModalVisible, toggleDetailsModal] = useToggle();

    const sortedGroupedData = useMemo(
        () => [...groupedData].sort((a, b) => b.status.localeCompare(a.status)),
        [groupedData]
    );

    const [modalData, setModalData] = useState<Activity>();

    const renderActivity: ListRenderItem<Activity> = useCallback(
        ({ item }) => {
            return (
                <ActivityCard
                    onClickViewDetails={() => {
                        setModalData(item);
                        toggleDetailsModal();
                    }}
                    item={item}
                />
            );
        },
        [toggleDetailsModal]
    );

    const renderStatus: ListRenderItem<Activity> = useCallback(
        ({ item }) => {
            return (
                <div className={styles.statusWrapper}>
                    <ActivityStatus status={item.status} />
                    {item.status === 'completed' ? (
                        <div className={styles.linkWrapper}>
                            <Link to="/activities/completed">
                                <Typography
                                    body
                                    level={3}
                                    semibold
                                    className={styles.completedLink}
                                >
                                    View completed activities
                                </Typography>
                            </Link>
                        </div>
                    ) : (
                        <List
                            data={item.data}
                            renderItem={renderActivity}
                            keyExtractor={keyExtractor}
                        />
                    )}
                </div>
            );
        },
        [renderActivity]
    );

    return (
        <div className={styles.container}>
            <DashboardHeader title="Activities" />
            <List
                data={sortedGroupedData}
                renderItem={renderStatus}
                keyExtractor={keyExtractor}
                className={styles.activityWrapper}
            />
            <ActivityDetailsModal
                data={modalData}
                isVisible={detailsModalVisible}
                handleToggle={toggleDetailsModal}
            />
        </div>
    );
};

export default Activities;
