import React from 'react';

import { Outlet, ScrollRestoration } from 'react-router-dom';

import Sidebar from 'components/Sidebar';

import styles from './styles.scss';

const Layout: React.FC = () => {
    return (
        <main className={styles.main}>
            <ScrollRestoration />
            <Sidebar />
            <Outlet />
        </main>
    );
};

export default Layout;
