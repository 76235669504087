import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getEpic, getResult } from 'services/bootstrap';

import { EpicType, Result } from 'services/types';
import { RootState } from 'store';
import { useAppSelector } from 'hooks/store';
import { regionActivity } from 'store/slices/selectors/activity';

const useResultActivityList = () => {
    const activityData = useAppSelector((state) => regionActivity(state));

    const { epic }: { epic: EpicType[] } = useSelector((state: RootState) => state.epic);
    const { result: resultData }: { result: Result[] } = useSelector(
        (state: RootState) => state.result
    );

    useEffect(() => {
        getEpic();
        getResult();
    }, []);

    const resultActivityList = useMemo(
        () =>
            resultData?.map((r: Result) => {
                const filterArr = epic
                    ?.map((e) => {
                        const filteredActivity = activityData?.filter((a) => e.id === a.epic);
                        return { result: e.result, subActivities: filteredActivity };
                    })
                    .filter((a) => a.result === r.id)
                    .map((a) => a.subActivities);
                return {
                    result: r.name,
                    subActivities: filterArr.flat().sort((a, b) => (a.name < b.name ? -1 : 1))
                };
            }),
        [epic, activityData, resultData]
    );
    return resultActivityList;
};

export default useResultActivityList;
