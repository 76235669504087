import React from 'react';

import Tabs, {
    Tab,
    TabsProps,
    TabChangeCallback,
    HeaderClickCallback,
    TabRenderHeader
} from '@ra/components/Tabs';

import styles from './styles.scss';

const _Tabs: React.FC<TabsProps> = (props) => {
    const { headerClassName, tabItemClassName, activeTabItemClassName, ...otherProps } = props;

    return (
        <Tabs
            headerClassName={styles.tabHeader}
            tabItemClassName={styles.tabHeaderItem}
            activeTabItemClassName={styles.tabHeaderItemActive}
            {...otherProps}
        />
    );
};

export { Tab };
export type { TabChangeCallback, HeaderClickCallback, TabRenderHeader };

export default _Tabs;
