import React from 'react';
import { useRouteError, Link } from 'react-router-dom';
import { BiHomeAlt } from 'react-icons/bi';

import Button from 'components/Button';
import Typography from 'components/Typography';

import styles from './styles.scss';
import Page404 from './Page404';

type RouteError = { status?: number; data: any; statusText?: string; message?: string };

const ErrorPage: React.FC = () => {
    const error = useRouteError() as RouteError;

    if (error.status === 404) {
        return <Page404 />;
    }

    return (
        <div className={styles.container}>
            <Typography heading level={1} semibold>
                Oops!
            </Typography>
            <Typography body level={2} light>
                Sorry, an unexpected error has occurred.
            </Typography>{' '}
            <Typography body level={3} semibold>
                {error.status && `${error.status} - `} {error.statusText || error.message}
            </Typography>
            <Link to="/">
                <Button leftIcon={BiHomeAlt}>Go to homepage</Button>
            </Link>
        </div>
    );
};

export default ErrorPage;
