import React, { useMemo } from 'react';

import Button, { ButtonProps } from '@ra/components/Button';
import Typography from 'components/Typography';

import cs from '@ra/cs';

import styles from './styles.scss';

type ComponentType<P = Record<string, 'never'>> =
    | React.ComponentClass<P, any>
    | React.FunctionComponent<P>;

export interface CustomButtonProps extends ButtonProps {
    loading?: boolean;
    secondary?: boolean;
    subtle?: boolean;
    text?: boolean;
    large?: boolean;
    small?: boolean;
    leftIcon?: ComponentType;
    rightIcon?: ComponentType;
    iconClassName?: string;
}

const _Button: React.FC<CustomButtonProps> = (props) => {
    const {
        disabled,
        loading,
        className,
        large,
        small,
        secondary,
        subtle,
        children,
        text,
        leftIcon: LeftIcon,
        rightIcon: RightIcon,
        ...buttonProps
    } = props;

    const level = useMemo(() => {
        if (large) {
            return 3;
        }
        if (small) {
            return 5;
        }
        return 4;
    }, [large, small]);

    return (
        <Button
            className={cs(className, styles.button, {
                [styles.buttonLarge]: large,
                [styles.buttonSmall]: small,
                [styles.buttonDisabled]: disabled || loading,
                [styles.buttonSecondary]: secondary,
                [styles.buttonSecondaryDisabled]: secondary && (disabled || loading),
                [styles.buttonSubtle]: subtle,
                [styles.buttonSubtleDisabled]: subtle && (disabled || loading),
                [styles.buttonText]: text,
                [styles.buttonTextDisabled]: text && (disabled || loading),
                [styles.buttonLeftIcon]: LeftIcon,
                [styles.buttonRightIcon]: RightIcon,
                [styles.buttonNoIcon]: !(LeftIcon || RightIcon)
            })}
            disabled={disabled || loading}
            {...buttonProps}
        >
            <Typography body semibold level={level} element="div" className={styles.content}>
                {LeftIcon && <LeftIcon className={styles.leftIcon} />}
                {children}
                {RightIcon && <RightIcon className={styles.rightIcon} />}
            </Typography>
        </Button>
    );
};

export default _Button;
