import { useMemo } from 'react';

import Typography from 'components/Typography';

import { timeSince } from 'utils/time';
import { Actions } from 'services/types';

import styles from './styles.scss';

const ActionItem = ({ item }: { item: Actions }) => {
    const backgroundColor = useMemo(() => {
        let hash = 0;
        for (let i = 0; i < item.actorObjStr.length; i++) {
            hash = item.actorObjStr.charCodeAt(i) + ((hash << 5) - hash);
        }
        return `hsl(${hash % 360},50%,80%)`;
    }, [item.actorObjStr]);

    return (
        <div className={styles.actionItem}>
            <div className={styles.userImage} style={{ backgroundColor: backgroundColor }}>
                <Typography heading level={2} semibold>
                    {item?.actorInfo?.firstName?.charAt(0)}
                </Typography>
            </div>
            <div className={styles.actionInfo}>
                <Typography body level={3} semibold className={styles.userName}>
                    {item?.actorInfo?.firstName} {item?.actorInfo?.lastName}
                </Typography>
                <div className={styles.info}>
                    <Typography body level={3} className={styles.title}>
                        {item?.description}
                    </Typography>
                    <Typography body level={4} semibold className={styles.timeSince}>
                        {timeSince(item?.createdAt)}
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default ActionItem;
