import { useCallback } from 'react';

import Typography from 'components/Typography';

import List, { KeyExtractor, ListRenderItem } from '@ra/components/List';

import { ResultBeneficiariesProps } from 'services/types';
import { resultBeneficiaries } from 'services/data/resultBeneficiaries';

import styles from './styles.scss';

const keyExtractor: KeyExtractor<ResultBeneficiariesProps> = (item) => item.id;

const ResultBeneficiaries = ({
    data,
    totalBeneficiaries
}: {
    data: any;
    totalBeneficiaries: any;
}) => {
    const renderItem: ListRenderItem<ResultBeneficiariesProps> = useCallback(
        ({ item }) => {
            const count = data?.find((el: any) => el.result.includes(`R${item.id}.`));
            return (
                <div className={styles.beneficiariesItem}>
                    {item.id === 0 ? (
                        <Typography body level={3} semibold className={styles.heading}>
                            Total Beneficiaries
                        </Typography>
                    ) : (
                        <div className={styles.titleWrapper}>
                            <div className={styles.idWrapper}>
                                <Typography body level={4} semibold className={styles.itemId}>
                                    R{item.id}
                                </Typography>
                            </div>
                            <Typography body level={4} semibold className={styles.itemTitle}>
                                {item.result}
                            </Typography>
                        </div>
                    )}
                    <ul className={styles.contentWrapper}>
                        {Object.entries(item).map(([key, value]) => {
                            if (key !== 'result' && key !== 'id') {
                                return (
                                    <li key={key} className={styles.contentData}>
                                        <Typography
                                            body
                                            level={4}
                                            className={styles.beneficiariesKey}
                                        >
                                            {key}
                                        </Typography>
                                        <Typography
                                            body
                                            level={4}
                                            semibold
                                            className={styles.beneficiariesValue}
                                        >
                                            {key === 'Individual' && item.id === 0
                                                ? `${totalBeneficiaries || 0} / `
                                                : key === 'Individual' &&
                                                  `${count?.totalSum || 0} / `}
                                            {value}
                                        </Typography>
                                    </li>
                                );
                            } else return null;
                        })}
                    </ul>
                </div>
            );
        },
        [data, totalBeneficiaries]
    );

    return (
        <div className={styles.beneficiariesContainer}>
            <Typography body level={2} semibold className={styles.heading}>
                Result Beneficiaries
            </Typography>
            <List
                data={resultBeneficiaries}
                keyExtractor={keyExtractor}
                renderItem={renderItem}
                className={styles.beneficiariesWrapper}
            />
        </div>
    );
};

export default ResultBeneficiaries;
