import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Resource } from 'services/types';

interface ResourceReducerState {
    resource: Resource[];
}

const initialState: ResourceReducerState = {
    resource: []
};

const resourceSlice = createSlice({
    name: 'resource',
    initialState,
    reducers: {
        setResource: (state: ResourceReducerState, { payload }: PayloadAction<Resource[]>) => {
            state.resource = payload;
        }
    }
});

export const { setResource } = resourceSlice.actions;

export default resourceSlice.reducer;
