import { Outlet } from 'react-router-dom';

import Typography from 'components/Typography';

import styles from './styles.scss';

const AuthLayout: React.FC = () => {
    return (
        <main className={styles.main}>
            <Typography heading level={3} semibold className={styles.logo}>
                SUPER
            </Typography>
            <Outlet />
        </main>
    );
};

export default AuthLayout;
