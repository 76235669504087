import { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BiX } from 'react-icons/bi';
import { BsPlus } from 'react-icons/bs';

import Typography from 'components/Typography';
import Button from 'components/Button';
import Tabs, { Tab } from 'components/Tabs';
import AddDeliverablesModal from 'components/AddDeliverablesModal';
import HTMLParser from 'components/HtmlParser';
import EmptyComponent from 'components/EmptyComponent';

import cs from '@ra/cs';
import Modal from '@ra/components/Modal';
import useToggle from '@ra/hooks/useToggle';
import List, { KeyExtractor, ListRenderItem } from '@ra/components/List';
import SelectInput from '@ra/components/Form/SelectInput';
import usePromise from '@ra/hooks/usePromise';

import Api from 'services/api';
import { getActivity } from 'services/bootstrap';
import { Activity, Report, Resource, Region, ActivityRegions } from 'services/types';
import { RootState } from 'store';
import statusName from 'utils/status';
import Toast from 'services/toast';

import styles from './styles.scss';

const status = [
    { label: 'Overdue', value: 'overdue' },
    { label: 'In progress', value: 'in_progress' },
    { label: 'Upcoming', value: 'upcoming' },
    { label: 'Completed', value: 'completed' }
];

const beneficiaryName: any = {
    male: 'Male',
    female: 'Female',
    pwd: 'PWD',
    lgbtqi: 'LGBTQI++',
    lgbtq: 'LGBTQI++',
    dalit: 'Dalit',
    janajati: 'Janajati',
    otherCaste: 'Other Caste',
    total: 'Total',
    age0to59months: '0-59 Months',
    age5to17yrs: '5-17 Years',
    age18to49yrs: '18-49 Years',
    age50yrsPlus: '50 Years or more'
};

const order: any = {
    male: 1,
    female: 2,
    lgbtq: 3,
    dalit: 4,
    janajati: 5,
    otherCaste: 6,
    pwd: 7,
    age0to59months: 8,
    age5to17yrs: 9,
    age18to49yrs: 10,
    age50yrsPlus: 11,
    total: 12
};

const statusKeyExtractor = (item: { value: string }) => item?.value;
const statusValueExtractor = (item: { label: string }) => item?.label;

const keyExtractor: KeyExtractor<Activity> = (item) => item.id;

interface ModalProps {
    item?: Activity;
    isVisible: boolean;
    handleToggle: any;
    data?: Activity;
}

interface BeneficiaryProps {
    value: string | number;
    name: string;
    order: number;
}

const Beneficiary = ({ item }: { item: BeneficiaryProps }) => {
    return (
        <div className={styles.beneficiaryItem}>
            <Typography body level={4} className={styles.title}>
                {item?.name}
            </Typography>
            <Typography body level={3} semibold className={styles.value}>
                {item?.value || 0}
            </Typography>
        </div>
    );
};

const ActivityDetailsModal: React.FC<ModalProps> = (props) => {
    const { isVisible, handleToggle, data } = props;
    const [addDeliveryModalVisible, toggleAddDeliveryModal] = useToggle();

    const [modalData, setModalData] = useState<Activity>();

    const { report }: { report: Report[] } = useSelector((state: RootState) => state.activity);
    const { region }: { region: Region[] } = useSelector((state: RootState) => state.region);

    const [activeRegionActivity, setActiveRegionActivity] = useState<ActivityRegions & Report>();

    const regionActivity: any = useMemo(() => {
        const allData = data?.activityRegions.map((el) => {
            return {
                ...el,
                regionName: region.find((reg) => reg.id === el.region)
            };
        });
        return allData?.filter((el) => el.region !== null);
    }, [data?.activityRegions, region]);

    useEffect(() => {
        if (regionActivity) {
            setActiveRegionActivity(regionActivity[0]);
        }
    }, [regionActivity]);

    const activityReport = useMemo(
        () =>
            report?.filter(
                (el) => el.activity === data?.id && el.region.id === activeRegionActivity?.region
            ),
        [activeRegionActivity?.region, data?.id, report]
    );

    const activeReport = useMemo(() => {
        const data = activityReport?.map((el) => {
            const arr = Object.entries(el.beneficiaries).map(([name, value]) => ({
                name,
                value
            }));
            const mappedBeneficiaries = arr.map((el) => ({
                order: order[el.name] || 0,
                name: beneficiaryName[el.name] || el.name,
                value: el.value
            }));
            return {
                ...el,
                beneficiaries: mappedBeneficiaries?.sort((a, b) => (b.order > a.order ? -1 : 1))
            };
        });
        return data;
    }, [activityReport]);

    const handleRegionChange = useCallback(
        ({ activeTab }: { activeTab: string }) => {
            const newActiveRegion = regionActivity.find(
                (dt: ActivityRegions) => dt.regionName.name === activeTab
            );
            if (newActiveRegion) {
                setActiveRegionActivity(newActiveRegion);
            }
        },
        [regionActivity]
    );

    const handleAddDeliveryModal = useCallback(() => {
        handleToggle();
        toggleAddDeliveryModal();
        setModalData(data);
    }, [data, handleToggle, toggleAddDeliveryModal]);

    const renderResource: ListRenderItem<Resource> = useCallback(({ item }) => {
        return (
            <div className={styles.infoItem}>
                <Typography body level={3} className={styles.field}>
                    Attachments :
                </Typography>
                <a href={item.file} target="_blank" rel="noreferrer">
                    <Typography body semibold level={3} className={styles.fileName}>
                        {item?.file?.substring(item.file.lastIndexOf('/') + 1)}
                    </Typography>
                </a>
            </div>
        );
    }, []);

    const [{ result }, updateActivityStatus] = usePromise(Api.updateActivityStatus);

    const handleChangeStatus = useCallback(
        async (status: string) => {
            try {
                await updateActivityStatus(
                    {
                        status
                    },
                    activeRegionActivity?.id
                );
            } catch (err: any) {
                console.log(err);
                Toast.show(err?.detail, Toast.DANGER);
            }
        },
        [activeRegionActivity?.id, updateActivityStatus]
    );

    const handleGroupSelect = useCallback(
        ({ option }: { option: { value: string } }) => {
            if (option.value !== (activeRegionActivity?.status || result?.status)) {
                handleChangeStatus(option.value);
            }
        },
        [activeRegionActivity?.status, handleChangeStatus, result?.status]
    );

    const regionReportData = useMemo(
        () => report.filter((el) => el.activity === data?.id),
        [data?.id, report]
    );

    useEffect(() => {
        if (result) {
            getActivity();
            setActiveRegionActivity(result);
        }
    }, [result]);

    const totalBeneficiaries = useMemo(() => {
        const beneficiaries = activityReport?.map((e: any) => e.beneficiaries);
        if (beneficiaries.length > 0) {
            return beneficiaries?.reduce((acc: any, cur: any) => ({
                male: acc?.male + cur?.male,
                female: acc?.female + cur?.female,
                lgbtq: acc?.lgbtq + cur?.lgbtq,
                pwd: acc?.pwd + cur?.pwd,
                janajati: acc?.janajati + cur?.janajati,
                dalit: acc?.dalit + cur?.dalit,
                otherCaste: acc?.otherCaste + cur?.otherCaste,
                total: acc?.total + cur?.total,
                age0to59months: acc?.age0to59months + cur?.age0to59months,
                age5to17yrs: acc?.age5to17yrs + cur?.age5to17yrs,
                age18to49yrs: acc?.age18to49yrs + cur?.age18to49yrs,
                age50yrsPlus: acc?.age50yrsPlus + cur?.age50yrsPlus
            }));
        }
        return [];
    }, [activityReport]);

    const activityBeneficiaries = useMemo(() => {
        const arr = Object.entries(totalBeneficiaries).map(([name, value]) => ({ name, value }));
        const dt = arr.map((el) => ({
            order: order[el.name],
            name: beneficiaryName[el.name] || el.name,
            value: el.value
        }));
        return dt?.sort((a, b) => (b.order > a.order ? -1 : 1));
    }, [totalBeneficiaries]);

    const renderActivityBeneficiaries: ListRenderItem<BeneficiaryProps> = useCallback(
        ({ item }) => <Beneficiary item={item} />,
        []
    );

    return (
        <>
            <Modal className={styles.modal} isVisible={isVisible} onClose={handleToggle}>
                <div className={styles.header}>
                    <Typography heading level={4} bold>
                        Details
                    </Typography>
                    <BiX className={styles.closeIcon} size={22} onClick={handleToggle} />
                </div>
                <div className={styles.topBar}>
                    <div className={styles.infoItem}>
                        <Typography body level={4} semibold className={styles.field}>
                            Title
                        </Typography>
                        <Typography body level={3} bold className={styles.title}>
                            {data?.name}
                        </Typography>
                    </div>
                    <div className={styles.buttonWrapper}>
                        <Button
                            onClick={handleAddDeliveryModal}
                            small
                            type="submit"
                            className={styles.addButton}
                            leftIcon={BsPlus}
                        >
                            Add deliverables
                        </Button>
                    </div>
                </div>
                {data?.description && (
                    <div className={styles.description}>
                        <HTMLParser content={data?.description} />
                    </div>
                )}
                {regionActivity?.length === 0 ? (
                    <Typography level={3}>Reports not available !!</Typography>
                ) : (
                    <div className={styles.regionActivityContent}>
                        <Tabs onChange={handleRegionChange}>
                            {regionActivity?.map((lvl: ActivityRegions) => (
                                <Tab
                                    key={lvl.id}
                                    label={lvl.regionName?.name}
                                    title={lvl.regionName?.name || 'Region not defined'}
                                />
                            ))}
                        </Tabs>
                        <div className={styles.tabContent}>
                            <div className={styles.tabTopContent}>
                                <div className={styles.activeReportStatus}>
                                    <Typography body level={3} semibold className={styles.field}>
                                        Status :
                                    </Typography>
                                    <div className={styles.valueWrapper}>
                                        <SelectInput
                                            valueExtractor={statusValueExtractor}
                                            keyExtractor={statusKeyExtractor}
                                            options={status}
                                            placeholder="Select status"
                                            className={styles.select}
                                            optionsWrapperClassName={styles.optionsWrapper}
                                            selectOptionClassName={styles.selectOption}
                                            optionItemClassName={styles.optionItem}
                                            controlClassName={cs(styles.selectControl, {
                                                [styles.completed]:
                                                    result?.status === 'completed' ||
                                                    activeRegionActivity?.status === 'completed'
                                            })}
                                            onChange={handleGroupSelect}
                                            searchable={false}
                                            clearable={false}
                                            defaultValue={{
                                                label: statusName[
                                                    activeRegionActivity?.status as any
                                                ],
                                                value: activeRegionActivity?.status
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={styles.dateWrapper}>
                                    <Typography body level={3} className={styles.title}>
                                        Start Date :
                                    </Typography>
                                    <Typography body level={3} semibold className={styles.title}>
                                        {activeRegionActivity?.dateFrom}
                                    </Typography>
                                </div>
                                <div className={styles.dateWrapper}>
                                    <Typography body level={3} className={styles.title}>
                                        Due Date :
                                    </Typography>
                                    <Typography body level={3} semibold className={styles.title}>
                                        {activeRegionActivity?.dateTo}
                                    </Typography>
                                </div>
                            </div>
                            <Typography body level={3} semibold className={styles.contentHeading}>
                                Total Beneficiaries
                            </Typography>
                            <List
                                data={activityBeneficiaries}
                                renderItem={renderActivityBeneficiaries}
                                keyExtractor={keyExtractor}
                                className={styles.beneficiariesWrapper}
                                EmptyComponent={<EmptyComponent small />}
                            />
                            {activeReport.length > 0 && (
                                <>
                                    <div className={styles.hairLine} />
                                    <Typography
                                        heading
                                        level={4}
                                        semibold
                                        className={styles.reportHeading}
                                    >
                                        Deliverables
                                    </Typography>
                                </>
                            )}
                            {activeReport.map((item) => (
                                <div className={styles.reportCard}>
                                    <div className={styles.reportWrapper}>
                                        <div className={styles.reportItem}>
                                            <Typography
                                                body
                                                level={4}
                                                semibold
                                                className={styles.field}
                                            >
                                                Title
                                            </Typography>
                                            <Typography
                                                body
                                                level={3}
                                                bold
                                                className={styles.title}
                                            >
                                                {item?.name}
                                            </Typography>
                                        </div>
                                        {item?.organization && (
                                            <div className={styles.reportItem}>
                                                <Typography
                                                    body
                                                    level={4}
                                                    semibold
                                                    className={styles.field}
                                                >
                                                    Organization
                                                </Typography>
                                                <Typography
                                                    body
                                                    level={3}
                                                    bold
                                                    className={styles.organizationName}
                                                >
                                                    {item?.organization}
                                                </Typography>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <HTMLParser content={item?.description} />
                                        <Typography
                                            body
                                            level={3}
                                            semibold
                                            className={styles.contentHeading}
                                        >
                                            Beneficiaries
                                        </Typography>
                                        <List
                                            data={item?.beneficiaries}
                                            renderItem={renderActivityBeneficiaries}
                                            keyExtractor={keyExtractor}
                                            className={styles.reportBeneficiariesWrapper}
                                            EmptyComponent={<EmptyComponent small />}
                                        />
                                    </div>
                                    <List
                                        data={item?.resources}
                                        renderItem={renderResource}
                                        keyExtractor={keyExtractor}
                                        className={styles.submittedInfo}
                                        EmptyComponent={<EmptyComponent small />}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </Modal>
            <AddDeliverablesModal
                data={modalData}
                regionReportData={regionReportData}
                isVisible={addDeliveryModalVisible}
                handleToggle={toggleAddDeliveryModal}
            />
        </>
    );
};

export default ActivityDetailsModal;
