import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'store';

const resourceData = (state: RootState) => state.resource.resource;
const reportData = (state: RootState) => state.activity.report;
const activeRegion = (state: RootState) => state.region.activeRegion;

export const regionResource = createSelector(
    [resourceData, reportData, activeRegion],
    (resource, report, activeRegion) => {
        if (activeRegion.id) {
            const rep = report.map((e) => {
                return {
                    id: e.id,
                    region: e.region.id,
                    resource: e.resources[0].id
                };
            });
            // fixme: if report has multiple resources
            const data = resource.map((el) => {
                const resourceRegion = rep.find((e) => e.resource === el.id);
                return {
                    ...el,
                    region: resourceRegion?.region
                };
            });
            return data.filter((dt) => dt.region === activeRegion.id);
        }
        return resource;
    }
);
