import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FiActivity } from 'react-icons/fi';
import { AiOutlineCheck } from 'react-icons/ai';
import { RiGroupLine } from 'react-icons/ri';
import { BsGlobe2 } from 'react-icons/bs';
import { IconType } from 'react-icons/lib';

import DashboardHeader from 'components/DashboardHeader';
import Typography from 'components/Typography';

import Result from './Result';
import Tasks from './Tasks';
import ResultBeneficiaries from './ResultBeneficiaries';
import PartnersLogo from './PartnersLogo';

import { useAppSelector } from 'hooks/store';
import { regionActivity } from 'store/slices/selectors/activity';
import Api from 'services/api';
import { RootState } from 'store';

import usePromise from '@ra/hooks/usePromise';

import GenderCount from './BeneficiariesCount/GenderCount';
import CasteCount from './BeneficiariesCount/CasteCount';
import AgeGroupCount from './BeneficiariesCount/AgeGroupCount';

import styles from './styles.scss';

export interface StatsProps {
    title: string;
    count: number;
}

interface ItemProps extends StatsProps {
    icon?: IconType;
}

const Item: React.FC<ItemProps> = ({ title, icon: Icon, count }) => {
    return (
        <div className={styles.item}>
            <div className={styles.iconContainer}>{Icon && <Icon className={styles.icon} />}</div>
            <div className={styles.itemInfo}>
                <Typography heading level={2} bold>
                    {count}
                </Typography>
                <Typography body level={3} semibold className={styles.itemName}>
                    {title}
                </Typography>
            </div>
        </div>
    );
};

const Dashboard = () => {
    const activeRegion = useSelector((state: RootState) => state.region.activeRegion);
    const activityData = useAppSelector((state) => regionActivity(state));

    const [{ result: beneficiariesCount }, getBeneficiariesCount] = usePromise(
        Api.getBeneficiariesCount
    );

    const regionCount = useMemo(() => {
        const data = activityData.flatMap((el) => el.regions);
        const regionList = new Set(data.filter((elem) => elem != null));
        return regionList.size;
    }, [activityData]);

    const completedActivity = useMemo(
        () => activityData.filter((el) => el.status === 'completed').length,
        [activityData]
    );

    const allBeneficiariesCount = useMemo(() => {
        const data = beneficiariesCount?.reduce(
            (acc: any, cur: any) => ({
                maleSum: acc.maleSum + cur.maleSum,
                femaleSum: acc.femaleSum + cur.femaleSum,
                lgbtqSum: acc.lgbtqSum + cur.lgbtqSum,
                janajatiSum: acc.janajatiSum + cur.janajatiSum,
                dalitSum: acc.dalitSum + cur.dalitSum,
                otherCasteSum: acc.otherCasteSum + cur.otherCasteSum,
                totalSum: acc.totalSum + cur.totalSum,
                age0to59monthsSum: acc.age0to59monthsSum + cur.age0to59monthsSum,
                age5to17yrsSum: acc.age5to17yrsSum + cur.age5to17yrsSum,
                age18to49yrsSum: acc.age18to49yrsSum + cur.age18to49yrsSum,
                age50yrsPlusSum: acc.age50yrsPlusSum + cur.age50yrsPlusSum
            }),
            {
                maleSum: 0,
                femaleSum: 0,
                lgbtqSum: 0,
                janajatiSum: 0,
                dalitSum: 0,
                otherCasteSum: 0,
                totalSum: 0,
                age0to59monthsSum: 0,
                age5to17yrsSum: 0,
                age18to49yrsSum: 0,
                age50yrsPlusSum: 0
            }
        );
        return data;
    }, [beneficiariesCount]);

    useEffect(() => {
        getBeneficiariesCount({ region: activeRegion?.id || '' });
    }, [activeRegion?.id, getBeneficiariesCount]);

    return (
        <div className={styles.container}>
            <DashboardHeader title="Dashboard" />
            <div className={styles.dashboardContainer}>
                <div className={styles.header}>
                    <div className={styles.summary}>
                        <Item title="Activities" icon={FiActivity} count={activityData.length} />
                        <Item title="Completed" icon={AiOutlineCheck} count={completedActivity} />
                        <Item title="Organizations" icon={RiGroupLine} count={44} />
                        <Item title="Regions" icon={BsGlobe2} count={regionCount} />
                    </div>
                </div>
                <div className={styles.charts}>
                    <Result />
                    <Tasks data={activityData} />
                </div>
                <div className={styles.countChartWrapper}>
                    <GenderCount data={allBeneficiariesCount || {}} />
                    <CasteCount data={allBeneficiariesCount || {}} />
                    <AgeGroupCount data={allBeneficiariesCount || {}} />
                </div>
                <div className={styles.bottomContent}>
                    <ResultBeneficiaries
                        data={beneficiariesCount}
                        totalBeneficiaries={allBeneficiariesCount?.totalSum}
                    />
                </div>
                <PartnersLogo />
            </div>
        </div>
    );
};

export default Dashboard;
