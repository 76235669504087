import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from 'react-eva-icons';

import DashboardHeader from 'components/DashboardHeader';
import ActivityCard, { ActivityStatus } from 'components/ActivityCard';
import Typography from 'components/Typography';
import ActivityDetailsModal from 'components/ActivityDetailsModal';
import EmptyComponent from 'components/EmptyComponent';

import List, { KeyExtractor, ListRenderItem } from '@ra/components/List';

import useToggle from '@ra/hooks/useToggle';

import { Activity } from 'services/types';
import { useAppSelector } from 'hooks/store';
import { regionActivity } from 'store/slices/selectors/activity';

import styles from './styles.scss';

const keyExtractor: KeyExtractor<Activity> = (item) => item.id;

const CompletedActivities: React.FC = () => {
    const navigate = useNavigate();

    const [detailsModalVisible, toggleDetailsModal] = useToggle();

    const activityData = useAppSelector((state) => regionActivity(state));

    const handleBackClick = useCallback(() => navigate('/activities'), [navigate]);

    const completedData = useMemo(
        () => activityData.filter((el) => el.status === 'completed'),
        [activityData]
    );

    const [modalData, setModalData] = useState<Activity>();

    const renderItems: ListRenderItem<Activity> = useCallback(
        ({ item }) => {
            return (
                <ActivityCard
                    onClickViewDetails={() => {
                        setModalData(item);
                        toggleDetailsModal();
                    }}
                    item={item}
                />
            );
        },
        [toggleDetailsModal]
    );

    return (
        <div className={styles.container}>
            <DashboardHeader title="Activities" />
            <div onClick={handleBackClick} className={styles.backLink}>
                <Typography body level={3} semibold className={styles.backIcon}>
                    <Icon name="arrow-back" size="large" fill="#55606E" />
                    Back
                </Typography>
            </div>
            <div className={styles.activityWrapper}>
                <div className={styles.statusWrapper}>
                    <ActivityStatus status="completed" />
                    <List
                        data={completedData}
                        renderItem={renderItems}
                        keyExtractor={keyExtractor}
                        EmptyComponent={EmptyComponent}
                    />
                </div>
            </div>
            <ActivityDetailsModal
                data={modalData}
                isVisible={detailsModalVisible}
                handleToggle={toggleDetailsModal}
            />
        </div>
    );
};

export default CompletedActivities;
