import { useCallback, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { BsFillKeyFill } from 'react-icons/bs';

import DashboardHeader from 'components/DashboardHeader';

import Tabs, { Tab, type TabRenderHeader, type TabChangeCallback } from '@ra/components/Tabs';
import cs from '@ra/cs';

import EditProfile from './EditProfile';
import ChangePassword from './ChangePassword';

import styles from './styles.scss';

const Settings = () => {
    const [activeTab, setActiveTab] = useState<'profile' | 'password'>('profile');

    const handleTabChange: TabChangeCallback = useCallback(({ activeTab: newActiveTab }) => {
        setActiveTab(newActiveTab as 'password' | 'profile');
    }, []);
    const renderTabsHeader: TabRenderHeader = useCallback((tabHeaderProps) => {
        const { active, title, ...rest } = tabHeaderProps;

        return (
            <div
                className={cs(styles.headerItem, {
                    [styles.headerItemActive]: active
                })}
                {...rest}
            >
                {title === 'Edit Profile' ? <MdEdit size={24} /> : <BsFillKeyFill size={24} />}
                <span className={styles.tabTitle}>{title}</span>
            </div>
        );
    }, []);

    return (
        <div>
            <DashboardHeader title="Account Settings" />
            <div className={styles.container}>
                <Tabs
                    className={styles.tabs}
                    renderHeader={renderTabsHeader}
                    headerClassName={styles.tabsHeader}
                    contentContainerClassName={styles.contentContainer}
                    activeTab={activeTab}
                    onChange={handleTabChange}
                >
                    <Tab label="profile" title="Edit Profile" className={styles.tabContent}>
                        <EditProfile />
                    </Tab>
                    <Tab label="password" title="Change Password" className={styles.tabContent}>
                        <ChangePassword />
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
};

export default Settings;
