import { useCallback, useState, useMemo } from 'react';
import SVG from 'react-inlinesvg';

import DashboardHeader from 'components/DashboardHeader';
import Typography from 'components/Typography';
import Input from 'components/Input';
import ResourceCard from 'components/ResourceCard';
import EmptyComponent from 'components/EmptyComponent';

import cs from '@ra/cs';
import List, { KeyExtractor, ListRenderItem } from '@ra/components/List';

import { Resource, ResourceType } from 'services/types';
import { resourceType } from 'services/data/resources';
import { useAppSelector } from 'hooks/store';
import { regionResource } from 'store/slices/selectors/resource';

import styles from './styles.scss';

const keyExtractor: KeyExtractor<Resource> = (item) => item.id;

const ResourceItem = ({
    item,
    onClick,
    activeItem
}: {
    item: ResourceType;
    onClick?: React.MouseEventHandler;
    activeItem?: string | null;
}) => {
    return (
        <div
            onClick={onClick}
            className={cs(styles.resourceItem, {
                [styles.active]: item.value === activeItem
            })}
        >
            <SVG
                src={item.icon || item.iconName}
                className={cs(item.icon ? styles.iconStroke : styles.iconFill)}
            />
            <Typography body level={2} semibold className={styles.title}>
                {item.title}
            </Typography>
        </div>
    );
};

const Resources = () => {
    const resource = useAppSelector((state) => regionResource(state));

    const [searchValue, setSearchValue] = useState<string>('');
    const [resourceTopic, setResourceTopic] = useState<string | null>('');

    const filteredResourceData = useMemo(() => {
        if (resourceTopic) {
            return resource.filter((el) => el.resourceType === resourceTopic);
        } else if (searchValue) {
            return resource.filter((el) =>
                el.name.toLowerCase().includes(searchValue.toLowerCase())
            );
        } else {
            return resource;
        }
    }, [resource, resourceTopic, searchValue]);

    const renderTopic: ListRenderItem<ResourceType> = useCallback(
        ({ item }) => {
            return (
                <ResourceItem
                    onClick={() =>
                        resourceTopic === item.value
                            ? setResourceTopic(null)
                            : setResourceTopic(item.value)
                    }
                    item={item}
                    activeItem={resourceTopic}
                />
            );
        },
        [resourceTopic]
    );

    const renderData: ListRenderItem<Resource> = useCallback(({ item }) => {
        return <ResourceCard item={item} />;
    }, []);

    const handleSearchChange = useCallback(({ value }: { value: string }) => {
        setResourceTopic(null);
        setSearchValue(value);
    }, []);

    const handleClearSearch = useCallback(() => setSearchValue(''), []);

    return (
        <div className={styles.container}>
            <DashboardHeader title="Resources" />
            <div className={styles.header}>
                <Input
                    containerClassName={styles.inputContainer}
                    className={styles.input}
                    leftIcon="search"
                    clearable={searchValue ? true : false}
                    onClear={handleClearSearch}
                    placeholder="Search"
                    onChange={handleSearchChange}
                />
            </div>
            <List
                className={styles.topicWrapper}
                data={resourceType}
                renderItem={renderTopic}
                keyExtractor={keyExtractor}
            />
            <List
                className={styles.resourceWrapper}
                data={filteredResourceData || []}
                renderItem={renderData}
                keyExtractor={keyExtractor}
                EmptyComponent={EmptyComponent}
            />
        </div>
    );
};

export default Resources;
