import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Form, { FormSubmitCallback, InputField } from '@ra/components/Form';

import Typography from 'components/Typography';
import Input from 'components/Input';
import Button from 'components/Button';

import Api from 'services/api';
import Toast from 'services/toast';

import usePromise from '@ra/hooks/usePromise';

import styles from './styles.scss';

const ChangePassword = () => {
    const navigate = useNavigate();

    const [{ loading }, changePassword] = usePromise(Api.changePassword);

    const [error, setError] = useState<any>(null);

    const handleChangePassword: FormSubmitCallback = useCallback(
        async (formData) => {
            setError(null);
            if (formData.new_password !== formData.re_new_password) {
                return Toast.show('Password does not match !!');
            }
            try {
                const result = await changePassword(formData);
                if (result) {
                    Toast.show('Password successfully changed !!', Toast.SUCCESS);
                }
            } catch (err) {
                console.log(err);
                setError(err);
            }
        },
        [changePassword]
    );

    const onClickForgotPassword = useCallback(() => {
        navigate('/forgot-password');
    }, [navigate]);

    return (
        <div className={styles.changePasswordCard}>
            <Typography heading level={3} semibold>
                Change Password
            </Typography>
            <Form
                formErrorClassName={styles.errorMessage}
                error={error}
                onSubmit={handleChangePassword}
                className={styles.formWrapper}
            >
                <div className={styles.inputWrapper}>
                    <InputField
                        className={styles.input}
                        component={Input}
                        name="old_password"
                        type="password"
                        label="Current Password"
                        labelClassName={styles.label}
                        placeholder="Enter your current password"
                        required
                    />
                    <InputField
                        className={styles.input}
                        component={Input}
                        name="new_password"
                        type="password"
                        label="New Password"
                        labelClassName={styles.label}
                        placeholder="Enter new password"
                        required
                    />
                    <InputField
                        className={styles.input}
                        component={Input}
                        name="re_new_password"
                        type="password"
                        label="Re-enter Password"
                        labelClassName={styles.label}
                        placeholder="Re-enter your new password"
                        required
                    />
                </div>
                <Button loading={loading} type="submit" className={styles.authButton}>
                    <Typography body level={3} semibold>
                        Change Password
                    </Typography>
                </Button>
            </Form>
            <div onClick={onClickForgotPassword}>
                <Typography body level={3} className={styles.forgotPasswordLink} semibold>
                    Forgot Password?
                </Typography>
            </div>
        </div>
    );
};

export default ChangePassword;
