import trainingMaterial from 'assets/icons/training-materials.svg';
import reports from 'assets/icons/reports.svg';
import deliverables from 'assets/icons/deliverables.svg';
import others from 'assets/icons/others.svg';

export const resourceType = [
    { id: 1, icon: trainingMaterial, title: 'Training', value: 'training' },
    { id: 2, iconName: reports, title: 'Reports', value: 'report' },
    { id: 3, icon: deliverables, title: 'Deliverables', value: 'delivery' },
    { id: 4, iconName: others, title: 'Others', value: 'other' }
];
