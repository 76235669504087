import { useState, useCallback } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Typography from 'components/Typography';
import Button from 'components/Button';
import OTPInput from 'components/OTPInput';

import Form, { FormSubmitCallback } from '@ra/components/Form';
import cs from '@ra/cs';
import usePromise from '@ra/hooks/usePromise';

import Toast from 'services/toast';
import Api from 'services/api';

import styles from './styles.scss';

const VerifyEmail = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [otpCode, setOtpCode] = useState('');
    const [error, setError] = useState<any>(null);

    const handleOtpChange = useCallback((otp: string) => {
        setError(null);
        setOtpCode(otp);
    }, []);

    const [{ loading }, passwordResetVerify] = usePromise(Api.passwordResetVerify);
    const [{ resendLoading }, forgotPassword] = usePromise(Api.forgotPassword);

    const handleVerifyOTP: FormSubmitCallback = useCallback(async () => {
        try {
            const result = await passwordResetVerify({
                username: location.state,
                pin: parseInt(otpCode)
            });
            if (result) {
                Toast.show('Email verified successfully !!', Toast.SUCCESS);
                navigate('/reset-password', {
                    state: { identifier: result?.identifier, username: location.state }
                });
            }
        } catch (err) {
            setError(err);
        }
    }, [location.state, navigate, otpCode, passwordResetVerify]);

    const handleReSendOTP: FormSubmitCallback = useCallback(async () => {
        setError(null);
        try {
            const result = await forgotPassword({ username: location.state });
            if (result) {
                Toast.show(`OTP sent to your mail: ${location.state}`, Toast.SUCCESS);
            }
        } catch (err) {
            console.log(err);
            setError(err);
        }
    }, [forgotPassword, location.state]);

    return (
        <div>
            <Link to="/forgot-password" className={styles.backButton}>
                <BiArrowBack className={styles.icon} />
                <Typography body level={3} semibold className={styles.title}>
                    Back
                </Typography>
            </Link>
            <div className={styles.loginBox}>
                <Typography heading level={3} semibold className={styles.heading}>
                    Forgot password
                </Typography>
                <Typography body level={3} semibold className={styles.subHeading}>
                    We’ve sent an OTP to <span>{location.state}</span> Please check your inbox and
                    follow instructions to reset your password.
                </Typography>
                <Form
                    formErrorClassName={styles.errorMessage}
                    error={error}
                    onSubmit={handleVerifyOTP}
                    className={styles.formWrapper}
                >
                    <div className={styles.inputWrapper}>
                        <OTPInput
                            autoFocus
                            isNumberInput
                            length={6}
                            className={styles.otpContainer}
                            inputClassName={cs(styles.otpInput, {
                                [styles.otpInputError]: error
                            })}
                            onChangeOTP={handleOtpChange}
                        />
                    </div>
                    <div onClick={handleReSendOTP} className={styles.resendLink}>
                        <Typography body level={4} className={styles.message}>
                            Didn’t receive OTP?
                        </Typography>
                        <Typography body level={4} semibold className={styles.button}>
                            Resend Code
                        </Typography>
                    </div>
                    <Button
                        loading={loading || resendLoading}
                        disabled={!otpCode}
                        type="submit"
                        className={styles.authButton}
                    >
                        <Typography body level={3} semibold>
                            Done
                        </Typography>
                    </Button>
                </Form>
            </div>
        </div>
    );
};

export default VerifyEmail;
