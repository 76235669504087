import React, { useState, useCallback, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { ImStack } from 'react-icons/im';
import { HiOutlineChartSquareBar } from 'react-icons/hi';
import { FiActivity } from 'react-icons/fi';
import { IconType } from 'react-icons';

import Typography from 'components/Typography';
import UserDropdown from 'components/UserDropdown';

import cs from '@ra/cs';

import Resources from 'assets/images/resources-black.svg';
import ResourcesActive from 'assets/images/resources-blue.svg';
import Recent from 'assets/images/recent-actions-black.svg';
import RecentActive from 'assets/images/recent-actions-blue.svg';

import styles from './styles.scss';

type NavItemProps = {
    to: string;
    icon?: IconType;
    svgIcon?: string;
    svgIconActive?: string;
    itemName: string;
};

const NavItem: React.FC<NavItemProps> = ({ to, icon: Icon, svgIcon, svgIconActive, itemName }) => {
    return (
        <NavLink
            to={to}
            className={({ isActive }) => cs(styles.navItem, { [styles.activeItem]: isActive })}
        >
            <span className={styles.navIcon}>
                {Icon && <Icon className={styles.iconStyle} />}
                {svgIcon && <img src={svgIcon} alt="icon" className={styles.svgIcon} />}
                {svgIconActive && (
                    <img src={svgIconActive} alt="active icon" className={styles.svgIconActive} />
                )}
            </span>
            <Typography body level={3} semibold className={styles.itemName}>
                {itemName}
            </Typography>
        </NavLink>
    );
};

const Sidebar = () => {
    const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false);
    const sideRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (!sideRef.current?.contains(event.target)) {
                setOpenMobileMenu(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleMobileMenu = useCallback(() => {
        setOpenMobileMenu(!openMobileMenu);
    }, [openMobileMenu]);

    return (
        <div className={cs({ [styles.container]: openMobileMenu })}>
            <div ref={sideRef} className={styles.sidebarContainer}>
                <div className={styles.navContent}>
                    <div className={styles.menuToggle} onClick={toggleMobileMenu}>
                        <span className={styles.menuToggleIcon}></span>
                    </div>
                    <div className={styles.logoContainer}>
                        <Typography heading level={3} semibold className={styles.logoTitle}>
                            SUPER
                        </Typography>
                    </div>
                    <div className={styles.navItems}>
                        <NavItem to="/" icon={ImStack} itemName="Dashboard" />
                        <NavItem
                            to="/gantt-chart"
                            icon={HiOutlineChartSquareBar}
                            itemName="Gantt Chart"
                        />
                        <NavItem to="/activities" icon={FiActivity} itemName="Activities" />
                        <NavItem
                            to="/recent-actions"
                            svgIcon={Recent}
                            svgIconActive={RecentActive}
                            itemName="Recent Actions"
                        />
                        <NavItem
                            to="/resources"
                            svgIcon={Resources}
                            svgIconActive={ResourcesActive}
                            itemName="Resources"
                        />
                    </div>
                </div>
                <div className={styles.userSection}>
                    <UserDropdown />
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
