import React, { useCallback, useState, useMemo, useRef } from 'react';
import { BiPlus, BiX } from 'react-icons/bi';

import Typography from 'components/Typography';

import DragDropFileInput, {
    DragDropFileInputProps,
    FileInputChangeCallback
} from '@ra/components/Form/DragDropFileInput';
import cs from '@ra/cs';

import Toast from 'services/toast';

import styles from './styles.scss';

const DropZoneComponent: React.FC = () => {
    return (
        <div className={styles.dropzoneContent}>
            <div className={styles.uploadIconContainer}>
                <BiPlus className={cs(styles.uploadIcon)} />
            </div>
            <Typography body level={3} semibold className={styles.uploadTitle}>
                Upload a file
            </Typography>
        </div>
    );
};

export interface FilePreviewProps {
    file?: File | null;
    className?: string;
    onRemove?: React.MouseEventHandler;
}

export const FilePreview: React.FC<FilePreviewProps> = ({ file, className, onRemove }) => {
    if (!file) {
        return null;
    }

    return (
        <div className={cs(styles.filePreviewContainer)}>
            <Typography body level={3} className={styles.fileName}>
                {file.name}
            </Typography>
            <BiX className={styles.closeIcon} onClick={onRemove} size={20} />
        </div>
    );
};

type CustomFileInputProps = DragDropFileInputProps & {
    info?: string;
    hidePreview?: boolean;
    fileInputContainerClassName?: string;
    previewClassName?: string;
    PreviewComponent?: React.ComponentType<any>;
};

const FileInput: React.FC<CustomFileInputProps> = (props) => {
    const {
        info,
        onChange,
        previewClassName,
        hidePreview,
        PreviewComponent,
        fileInputContainerClassName,
        ...otherProps
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    const [previewFile, setPreviewFile] = useState<File | undefined | null>();

    const handleChange: FileInputChangeCallback = useCallback(
        (payload) => {
            onChange?.(payload);
            const { files, rejections } = payload;
            if (rejections.length > 0 && rejections[0]?.errors?.length > 0) {
                const errorMessage = rejections[0].errors[0].message || 'File invalid';
                Toast.show(errorMessage, Toast.DANGER);
            }
            if (files.length > 0) {
                setPreviewFile(files[0]);
            }
        },
        [onChange]
    );

    const handleRemoveFile = useCallback(() => {
        if (inputRef?.current) {
            inputRef.current.value = '';
        }
        onChange?.({
            name: otherProps.name as string,
            files: [] as unknown as FileList,
            rejections: []
        });
        setPreviewFile(null);
    }, [onChange, otherProps]);

    const Preview = useMemo(() => PreviewComponent ?? FilePreview, [PreviewComponent]);

    return (
        <div className={fileInputContainerClassName}>
            <DragDropFileInput
                dropZoneClassName={styles.dropzone}
                activeDropZoneClassName={styles.dropzoneActive}
                DropZoneComponent={<DropZoneComponent />}
                onChange={handleChange}
                inputRef={inputRef}
                {...otherProps}
            />
            {!hidePreview && (
                <Preview
                    file={previewFile}
                    className={previewClassName}
                    onRemove={handleRemoveFile}
                />
            )}
        </div>
    );
};

export default FileInput;
