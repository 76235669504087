import Api from 'services/api';
import store from 'store';

import { setActivity, setReport } from 'store/slices/activity';
import { setEpic } from 'store/slices/epic';
import { setRegion } from 'store/slices/region';
import { setResource } from 'store/slices/resource';
import { setResult } from 'store/slices/result';

const dispatch = store.dispatch;

const tokenRefresh = async () => {
    const {
        auth: { refreshToken }
    } = store.getState();
    refreshToken && (await Api.refreshToken(refreshToken));
};

export const getActivity = async () => {
    try {
        const data = await Api.getActivity({ limit: -1, expand: 'activity_regions' });
        if (data) {
            dispatch(setActivity(data?.results || []));
        }
    } catch (error) {
        console.log(error);
    }
};

export const getReport = async () => {
    try {
        const data = await Api.getReport({ expand: 'region,resources' });
        if (data) {
            dispatch(setReport(data?.results || []));
        }
    } catch (error) {
        console.log(error);
    }
};

export const getResource = async () => {
    try {
        const data = await Api.getResource({ limit: -1 });
        if (data) {
            dispatch(setResource(data?.results || []));
        }
    } catch (error) {
        console.log(error);
    }
};

export const getEpic = async () => {
    try {
        const data = await Api.getEpic();
        if (data) {
            dispatch(setEpic(data?.results || []));
        }
    } catch (error) {
        console.log(error);
    }
};

export const getResult = async () => {
    try {
        const data = await Api.getResult();
        if (data) {
            dispatch(setResult(data?.results || []));
        }
    } catch (error) {
        console.log(error);
    }
};

export const getRegion = async () => {
    try {
        const data = await Api.getRegion();
        if (data) {
            dispatch(setRegion(data?.results || []));
        }
    } catch (error) {
        console.log(error);
    }
};

export const initializeData = async () => {
    await tokenRefresh();
    setInterval(tokenRefresh, 240 * 1000);

    getRegion();
    getReport();
    getActivity();
    getResource();
};
