import { combineReducers } from 'redux';

import authReducer from './auth';
import activityReducer from './activity';
import resourceReducer from './resource';
import regionReducer from './region';
import uiReducer from './ui';
import epicReducer from './epic';
import resultReducer from './result';

const rootReducer = combineReducers({
    auth: authReducer,
    activity: activityReducer,
    resource: resourceReducer,
    ui: uiReducer,
    epic: epicReducer,
    result: resultReducer,
    region: regionReducer
});

export default rootReducer;
