import { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Typography from 'components/Typography';
import Input from 'components/Input';
import Button from 'components/Button';

import Form, { FormSubmitCallback, InputField } from '@ra/components/Form';
import SelectInput from '@ra/components/Form/SelectInput';

import Api from 'services/api';
import Toast from 'services/toast';
import { useAppSelector } from 'hooks/store';
import type { RootState } from 'store';
import { Region } from 'services/types';
import usePromise from '@ra/hooks/usePromise';

import styles from './styles.scss';

const regionKeyExtractor = (item: { id: string }) => item?.id;
const regionValueExtractor = (item: { name: string }) => item?.name;

const EditProfile = () => {
    const { user } = useAppSelector((state) => state.auth);
    const { region }: { region: Region[] } = useSelector((state: RootState) => state.region);

    const [{ loading }, updateUser] = usePromise(Api.updateUser);

    const [error, setError] = useState<any>(null);
    const [selectedRegion, setSelectedRegion] = useState(user?.region);

    const handleRegionSelect = useCallback(({ option }: { option: { id: number } }) => {
        setSelectedRegion(option?.id);
    }, []);

    const fullName = useMemo(() => {
        return `${user?.firstName} ${user?.lastName}`;
    }, [user]);

    const userRegion = useMemo(
        () => region.find((el) => el.id === user?.region),
        [region, user?.region]
    );

    const handleUpdateProfile: FormSubmitCallback = useCallback(
        async (formData) => {
            const name = formData.fullName.split(' ');
            const first_name = name[0];
            const last_name = formData.fullName.substring(name[0].length).trim();
            if (fullName === formData.fullName && selectedRegion === userRegion?.id) {
                return Toast.show('No changes has been made !!', Toast.WARNING);
            }
            try {
                const result = await updateUser({
                    first_name,
                    last_name,
                    region: selectedRegion
                });
                if (result) {
                    Api.loadUserToStore();
                    Toast.show('Date successfully updated !!', Toast.SUCCESS);
                }
            } catch (err) {
                console.log(err);
                setError(err);
            }
        },
        [fullName, selectedRegion, updateUser, userRegion?.id]
    );

    return (
        <div className={styles.changePasswordCard}>
            <Typography heading level={3} semibold>
                Edit Profile
            </Typography>
            <Form
                formErrorClassName={styles.errorMessage}
                error={error}
                onSubmit={handleUpdateProfile}
                className={styles.formWrapper}
            >
                <div className={styles.inputWrapper}>
                    <InputField
                        className={styles.input}
                        component={Input}
                        name="fullName"
                        label="Name"
                        labelClassName={styles.label}
                        placeholder="Enter your full name"
                        defaultValue={fullName}
                        required
                    />
                    <InputField
                        className={styles.input}
                        component={Input}
                        name="email"
                        type="email"
                        label="Email"
                        labelClassName={styles.label}
                        defaultValue={user?.email}
                        disabled
                    />
                    <div className={styles.selectWrapper}>
                        <Typography body level={3} semibold className={styles.selectLabel}>
                            Region
                        </Typography>
                        <SelectInput
                            valueExtractor={regionValueExtractor}
                            keyExtractor={regionKeyExtractor}
                            options={region}
                            placeholder="Select region"
                            className={styles.select}
                            optionsWrapperClassName={styles.optionsWrapper}
                            selectOptionClassName={styles.selectOption}
                            optionItemClassName={styles.optionItem}
                            controlClassName={styles.selectControl}
                            onChange={handleRegionSelect}
                            searchable={false}
                            clearable={false}
                            defaultValue={userRegion}
                            disabled={!user?.isSuperuser}
                        />
                    </div>
                </div>
                <Button loading={loading} type="submit" className={styles.saveButton}>
                    <Typography body level={3} semibold>
                        Save Changes
                    </Typography>
                </Button>
            </Form>
        </div>
    );
};

export default EditProfile;
