import React, { useMemo } from 'react';

import cs from '@ra/cs';

import styles from './styles.scss';

interface TypographyVariants {
    display?: boolean;
    heading?: boolean;
    body?: boolean;
}

interface TypographyProps extends TypographyVariants {
    className?: string;
    children: React.ReactNode;
    element?: keyof JSX.IntrinsicElements;
    level: 1 | 2 | 3 | 4 | 5;
    bold?: boolean;
    semibold?: boolean;
    light?: boolean;
}

type DefaultElementGetter = (
    level: number,
    variant: TypographyVariants
) => keyof JSX.IntrinsicElements;

const getDefaultElement: DefaultElementGetter = (level, { display, heading, body }) => {
    if (display || heading) {
        return ('h' + level) as keyof JSX.IntrinsicElements;
    }
    return 'p' as keyof JSX.IntrinsicElements;
};

const Typography: React.FC<TypographyProps> = (props) => {
    const { element, children, className, display, heading, body, level, bold, semibold, light } =
        props;

    const InferredElement = useMemo(
        () => element || getDefaultElement(level, { display, heading, body }),
        [display, heading, body, level, element]
    );

    return (
        <InferredElement
            className={cs(className, styles.typography, {
                [styles[`display${level}`]]: display,
                [styles[`heading${level}`]]: heading,
                [styles[`body${level}`]]: body,
                [styles.bold]: bold,
                [styles.semibold]: semibold,
                [styles.light]: light
            })}
        >
            {children}
        </InferredElement>
    );
};

export default Typography;
