import Typography from 'components/Typography';

import styles from './styles.scss';

type EntryProps = {
    color: string;
    payload: {
        title: string;
    };
};

type LegendProps = {
    payload?: Array<{
        entry: EntryProps;
        index?: number;
    }>;
};

const PieChartLegend = ({ payload }: LegendProps) => {
    return (
        <ul className={styles.legend}>
            {payload?.map((entry: any, index: number) => {
                return (
                    <li key={`item-${index}`} className={styles.item}>
                        <span
                            className={styles.colorBox}
                            style={{
                                backgroundColor: entry.color
                            }}
                        />
                        <Typography body level={3} className={styles.itemTitle}>
                            {entry.payload.name}
                        </Typography>
                    </li>
                );
            })}
        </ul>
    );
};

export default PieChartLegend;
