import { useState, useCallback } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import Typography from 'components/Typography';
import Input from 'components/Input';
import Button from 'components/Button';

import Form, { FormSubmitCallback, InputField } from '@ra/components/Form';

import Toast from 'services/toast';
import Api from 'services/api';
import usePromise from '@ra/hooks/usePromise';

import styles from './styles.scss';

const ResetPassword = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [error, setError] = useState<any>(null);

    const [{ loading }, resetPassword] = usePromise(Api.resetPassword);

    const handleResetPassword: FormSubmitCallback = useCallback(
        async (formData) => {
            try {
                const result = await resetPassword({
                    username: location.state.username,
                    identifier: location.state.identifier,
                    password: formData.password,
                    re_password: formData.re_password
                });
                if (result) {
                    Toast.show('New password created successfully !!', Toast.SUCCESS);
                    navigate('/');
                }
            } catch (err) {
                console.log(err);
                setError(err);
            }
        },
        [location.state, navigate, resetPassword]
    );

    return (
        <div className={styles.loginBox}>
            <Typography heading level={3} semibold className={styles.heading}>
                Reset password
            </Typography>
            <Form
                formErrorClassName={styles.errorMessage}
                error={error}
                onSubmit={handleResetPassword}
                className={styles.formWrapper}
            >
                <div className={styles.inputWrapper}>
                    <InputField
                        className={styles.input}
                        component={Input}
                        name="password"
                        type="password"
                        label="Password"
                        labelClassName={styles.label}
                        placeholder="Enter new password"
                        required
                    />
                    <InputField
                        className={styles.input}
                        component={Input}
                        name="re_password"
                        type="password"
                        label="Password"
                        labelClassName={styles.label}
                        placeholder="Re-enter your new password"
                        required
                    />
                </div>
                <Button loading={loading} type="submit" className={styles.authButton}>
                    <Typography body level={3} semibold>
                        Update Password
                    </Typography>
                </Button>
            </Form>
            <Link to="/login" className={styles.backButton}>
                <Typography body level={3} className={styles.title}>
                    Back to
                </Typography>
                <Typography body level={3} semibold className={styles.link}>
                    Log in
                </Typography>
            </Link>
        </div>
    );
};

export default ResetPassword;
