import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Result } from 'services/types';

interface ResultReducerState {
    result: Result[];
}

const initialState: ResultReducerState = {
    result: []
};

const resultSlice = createSlice({
    name: 'result',
    initialState,
    reducers: {
        setResult: (state: ResultReducerState, { payload }: PayloadAction<Result[]>) => {
            state.result = payload;
        }
    }
});

export const { setResult } = resultSlice.actions;

export default resultSlice.reducer;
