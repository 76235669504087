import React, { useCallback, useMemo } from 'react';
import { PieChart, Pie, Legend, Cell, ResponsiveContainer, Label } from 'recharts';

import Typography from 'components/Typography';

import { Activity } from 'services/types';

import styles from './styles.scss';

type EntryProps = {
    color: string;
    payload: {
        title: string;
    };
};

type LegendProps = {
    payload?: Array<{
        entry: EntryProps;
        index?: number;
    }>;
};

type CustomLabelProps = {
    viewBox?: {
        cx?: number;
        cy?: number;
    };
    percent?: number;
};

const CustomLabel = ({ viewBox, percent }: CustomLabelProps) => {
    if (viewBox?.cx && viewBox?.cy) {
        return (
            <text x={viewBox?.cx - 26} y={viewBox?.cy - 5} className={styles.centerText}>
                {percent + '%'}
                <tspan x={viewBox?.cx - 50} y={viewBox?.cy + 20} className={styles.completedText}>
                    COMPLETED
                </tspan>
            </text>
        );
    }
    return null;
};

const RenderLegend = ({ payload }: LegendProps) => {
    return (
        <ul className={styles.legend}>
            {payload?.map((entry: any, index: number) => {
                return (
                    <li key={`item-${index}`} className={styles.item}>
                        <span
                            className={styles.colorBox}
                            style={{
                                backgroundColor: entry.color
                            }}
                        />
                        <Typography body level={3} className={styles.itemTitle}>
                            {entry?.payload?.title}
                        </Typography>
                    </li>
                );
            })}
        </ul>
    );
};

const Tasks = ({ data }: { data: Activity[] }) => {
    const colorSelect = useCallback((title: string) => {
        if (title === 'completed') return '#59ba47';
        else if (title === 'upcoming') return '#f49e4c';
        else if (title === 'in_progress') return '#4285f4';
        else return '#ee402d';
    }, []);

    const extractCount = useCallback(
        (status: string) => {
            return data.filter((el) => el.status === status).length;
        },
        [data]
    );

    const pieChartData = useMemo(
        () => [
            {
                title: 'Completed',
                count: extractCount('completed'),
                color: colorSelect('completed')
            },
            { title: 'Upcoming', count: extractCount('upcoming'), color: colorSelect('upcoming') },
            {
                title: 'In-progress',
                count: extractCount('in_progress'),
                color: colorSelect('in_progress')
            },
            { title: 'Overdue', count: extractCount('overdue'), color: colorSelect('overdue') }
        ],
        [extractCount, colorSelect]
    );

    const completedPercentage = useMemo(
        () => Math.round((extractCount('completed') / data.length) * 100),
        [data.length, extractCount]
    );
    return (
        <div className={styles.pieContainer}>
            <Typography body level={2} semibold className={styles.pieTitle}>
                Tasks
            </Typography>
            <ResponsiveContainer height="80%">
                <PieChart>
                    <Pie
                        data={pieChartData}
                        dataKey="count"
                        cx="50%"
                        cy="50%"
                        innerRadius={75}
                        outerRadius={95}
                        stroke=""
                    >
                        {pieChartData?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                        <Label
                            width={40}
                            position="center"
                            content={<CustomLabel percent={completedPercentage} />}
                        />
                    </Pie>
                    <Legend content={<RenderLegend />} />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default Tasks;
