import { useCallback, useState, useEffect, useMemo } from 'react';
import { BiX } from 'react-icons/bi';
import { useSelector } from 'react-redux';

import Typography from 'components/Typography';
import Input from 'components/Input';
import Button from 'components/Button';
import FileInput from 'components/FileInput';

import { InputProps } from '@ra/components/Form/Input';
import Modal from '@ra/components/Modal';
import Form, { FormSubmitCallback, InputField } from '@ra/components/Form';
import type { FileInputChangeCallback } from '@ra/components/Form/DragDropFileInput';
import SelectInput, { SelectInputChangeCallback } from '@ra/components/Form/SelectInput';
import NumericInput from '@ra/components/Form/NumericInput';

import Toast from 'services/toast';
import Api from 'services/api';
import { getReport, getResource } from 'services/bootstrap';
import { Activity, Region, Report } from 'services/types';
import type { RootState } from 'store';

import cs from '@ra/cs';
import usePromise from '@ra/hooks/usePromise';

import styles from './styles.scss';

interface SelectType {
    id: number;
    name: string;
}

const organizations = [
    { id: 1, name: 'UNDP' },
    { id: 2, name: 'UNICEF' },
    { id: 3, name: 'UN Women' }
];

const regionKeyExtractor = (item: { id: string }) => item?.id;
const regionValueExtractor = (item: { name: string }) => item?.name;

const regionIdExtractor: SelectInputChangeCallback<SelectType> = ({ option }) => option?.id || null;
const organizationTitleExtractor: SelectInputChangeCallback<SelectType> = ({ option }) =>
    option?.name || null;

const beneficiariesInput = [
    { id: 1, label: 'Male', name: 'male' },
    { id: 2, label: 'Female', name: 'female' },
    { id: 3, label: 'LGBTQI++', name: 'lgbtq' },
    { id: 4, label: 'Janajati', name: 'janajati' },
    { id: 5, label: 'Dalit', name: 'dalit' },
    { id: 6, label: 'Other Caste', name: 'other_caste' },
    { id: 7, label: 'PWD', name: 'pwd' },
    { id: 8, label: '0-59 Months', name: 'age_0to59months' },
    { id: 9, label: '5-17 Years', name: 'age_5to17yrs' },
    { id: 10, label: '18-49 Years', name: 'age_18to49yrs' },
    { id: 11, label: '50 Years or more', name: 'age_50yrs_plus' }
];

interface ModalProps {
    item?: Activity;
    isVisible: boolean;
    handleToggle: any;
    data?: Activity;
    regionReportData: Report[];
}

interface SmallInputProps extends InputProps {
    label: string;
}

const fileExtractor: FileInputChangeCallback = ({ files }) => (files.length > 0 ? files[0] : null);

const SmallInput: React.FC<SmallInputProps> = ({ ...inputProps }) => {
    return (
        <div className={styles.smallInput}>
            <NumericInput className={styles.input} {...inputProps} />
        </div>
    );
};

const AddDeliverablesModal: React.FC<ModalProps> = (props) => {
    const { isVisible, handleToggle, data } = props;

    const { region }: { region: Region[] } = useSelector((state: RootState) => state.region);

    const [error, setError] = useState<any>(null);

    const [{ result, loading }, addDeliverable] = usePromise(Api.addDeliverable);

    const [beneficiaries, setBeneficiaries] = useState<any>({});

    const activityRegions = useMemo(() => {
        const regionList = region.filter((el) => data?.regions.includes(el?.id as never));
        return regionList;
    }, [data?.regions, region]);

    const handleBeneficiariesChange = useCallback(
        ({ name, value }: { name: string; value: string }) => {
            setBeneficiaries({ ...beneficiaries, [name]: parseInt(value) });
        },
        [beneficiaries]
    );

    useEffect(() => {
        if (!isVisible) {
            setBeneficiaries({});
        }
    }, [isVisible]);

    const handleSaveDeliverables: FormSubmitCallback = useCallback(
        async (formData) => {
            const activity: any = data?.id;
            const submitData = new FormData();

            setError(null);

            const totalGender = beneficiaries?.male + beneficiaries?.female + beneficiaries?.lgbtq;

            const totalCaste =
                beneficiaries?.janajati + beneficiaries?.dalit + beneficiaries?.other_caste;

            const totalAge =
                beneficiaries?.age_0to59months +
                beneficiaries?.age_5to17yrs +
                beneficiaries?.age_18to49yrs +
                beneficiaries?.age_50yrs_plus;

            if (totalGender !== totalCaste || totalGender !== totalAge) {
                return Toast.show(
                    'Total gender data, caste data and age group data is not equal. !!',
                    Toast.WARNING
                );
            }

            if (formData.file.size > 104857600) {
                return Toast.show('This file size must be less than 100MB', Toast.WARNING);
            }

            const allBeneficiaries = {
                ...beneficiaries,
                total: beneficiaries?.male + beneficiaries?.female + beneficiaries?.lgbtq
            };

            const entry = {
                name: formData.name,
                description: formData.description,
                activity,
                organization: formData.organization,
                region: formData.region,
                beneficiaries: JSON.stringify(allBeneficiaries),
                resources: { name: formData.name, resource_type: 'delivery', file: formData.file }
            };

            for (const entryKey in entry) {
                if (entryKey === 'resources') {
                    for (const resourceKey in entry[entryKey]) {
                        submitData.append(
                            `resources[0]${[resourceKey]}`,
                            entry[entryKey as keyof typeof entry][resourceKey as keyof typeof entry]
                        );
                    }
                } else {
                    submitData.append(entryKey, entry[entryKey as keyof typeof entry]);
                }
            }

            try {
                const result = await addDeliverable(submitData, {
                    headers: new Headers()
                });
                if (result) {
                    handleToggle();
                    Toast.show('Successfully added !!', Toast.SUCCESS);
                }
            } catch (err: any) {
                console.log(err);
                setError(err);
                if (err[0]?.region) {
                    Toast.show(err[0]?.region, Toast.DANGER);
                }
            }
        },
        [addDeliverable, beneficiaries, data?.id, handleToggle]
    );

    const handleInvalidSubmit = useCallback((reason: string) => {
        if (reason === 'required') {
            Toast.show('Please complete all required fields!', Toast.DANGER);
        }
    }, []);

    useEffect(() => {
        if (result) {
            getReport();
            getResource();
        }
    }, [result]);

    return (
        <Modal className={styles.modal} isVisible={isVisible} closeOnEscape onClose={handleToggle}>
            <div className={styles.header}>
                <Typography heading level={4} bold>
                    Add Deliverable
                </Typography>
                <BiX className={styles.closeIcon} size={22} onClick={handleToggle} />
            </div>
            <Form
                formErrorClassName={styles.errorMessage}
                error={error}
                onSubmit={handleSaveDeliverables}
                className={styles.formWrapper}
                onInvalidSubmit={handleInvalidSubmit}
            >
                <div className={styles.selectWrapper}>
                    <Typography body level={3} semibold className={styles.selectLabel}>
                        Region
                    </Typography>
                    <InputField
                        name="region"
                        component={SelectInput}
                        options={activityRegions}
                        valueExtractor={regionValueExtractor}
                        keyExtractor={regionKeyExtractor}
                        fieldValueExtractor={regionIdExtractor}
                        required
                        placeholder="Select region"
                        className={styles.select}
                        optionsWrapperClassName={styles.optionsWrapper}
                        selectOptionClassName={styles.selectOption}
                        optionItemClassName={styles.optionItem}
                        controlClassName={styles.selectControl}
                        searchable={false}
                        clearable={false}
                    />
                </div>
                <div className={styles.selectWrapper}>
                    <Typography body level={3} semibold className={styles.selectLabel}>
                        Organization
                    </Typography>
                    <InputField
                        name="organization"
                        component={SelectInput}
                        options={organizations}
                        valueExtractor={regionValueExtractor}
                        fieldValueExtractor={organizationTitleExtractor}
                        keyExtractor={regionKeyExtractor}
                        required
                        placeholder="Select organization"
                        className={styles.select}
                        optionsWrapperClassName={styles.optionsWrapper}
                        selectOptionClassName={styles.selectOption}
                        optionItemClassName={styles.optionItem}
                        controlClassName={styles.selectControl}
                        searchable={false}
                        clearable={false}
                    />
                </div>
                <div className={styles.inputWrapper}>
                    <InputField
                        className={styles.input}
                        component={Input}
                        name="name"
                        label="Title"
                        labelClassName={styles.label}
                        required
                    />
                    <InputField
                        className={styles.textarea}
                        component="textarea"
                        name="description"
                        label="Description"
                        labelClassName={cs(styles.label, styles.textareaLabel)}
                        rows={5}
                        required
                    />
                </div>
                <div>
                    <Typography body level={3} semibold className={styles.formLabel}>
                        Beneficiaries
                    </Typography>
                    <div className={styles.smallInputWrapper}>
                        {beneficiariesInput.map((item) => (
                            <InputField
                                component={SmallInput}
                                containerClassName={styles.inputFieldContainer}
                                label={item.label}
                                labelClassName={styles.smallInputLabel}
                                name={item.name}
                                onChange={handleBeneficiariesChange}
                                required
                            />
                        ))}
                        <InputField
                            component={SmallInput}
                            containerClassName={styles.inputFieldContainer}
                            label="Total"
                            labelClassName={styles.smallInputLabel}
                            readOnly
                            value={
                                beneficiaries.male + beneficiaries.female + beneficiaries.lgbtq || 0
                            }
                            className={styles.readOnlyInput}
                        />
                    </div>
                </div>
                <div>
                    <InputField
                        component={FileInput}
                        name="file"
                        label="Attachment"
                        labelClassName={styles.fileInputLabel}
                        fieldValueExtractor={fileExtractor}
                        required
                    />
                </div>
                <div className={styles.buttonWrapper}>
                    <Button
                        loading={loading}
                        disabled={activityRegions.length <= 0}
                        type="submit"
                        className={styles.authButton}
                    >
                        <Typography body level={3} semibold>
                            Save
                        </Typography>
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default AddDeliverablesModal;
