import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ViewMode, Gantt, Task } from 'gantt-task-react';
import { TaskType } from 'gantt-task-react/dist/types/public-types';
import 'gantt-task-react/dist/index.css';

import { AiFillCaretRight } from 'react-icons/ai';
import { MdDone } from 'react-icons/md';

import Typography from 'components/Typography';
import DashboardHeader from 'components/DashboardHeader';
import Button from 'components/Button';

import { Activity, ResultActivityListProps } from 'services/types';
import useResultActivity from 'hooks/useResultActivity';

import cs from '@ra/cs';
import useSize from '@ra/hooks/useSize';

import styles from './styles.scss';

type progressionListProps = {
    name: string;
    color: string;
    value: string;
};

const CreateTaskListHeader: React.FC<{
    headerHeight: number;
}> = ({ headerHeight }) => {
    return (
        <div
            style={{
                height: headerHeight,
                borderRight: '1px solid #edeff0'
            }}
        ></div>
    );
};

const CreateTaskListTable: React.FC<{
    rowHeight: number;
    rowWidth: string;
    tasks: Task[];
    onExpanderClick: (task: Task) => void;
}> = ({ rowHeight, rowWidth, tasks, onExpanderClick }) => {
    const [hoverItem, setHoverItem] = useState<string>('');
    const handleMouseEnterItem = useCallback((e: any) => {
        setHoverItem(e.target.id);
    }, []);
    const handleMouseLeaveItem = useCallback(() => setHoverItem(''), []);
    const { width } = useSize();

    const truncateLength = useMemo(() => {
        if (width) {
            if (width < 512) return 5;
            else if (width < 992) return 25;
            else return 35;
        }
    }, [width]);

    const truncate = useCallback(
        (name: string) => {
            if (truncateLength)
                return name.length < truncateLength
                    ? name
                    : name.substring(0, truncateLength) + '...';
        },
        [truncateLength]
    );

    return (
        <div className={styles.taskListWrapper}>
            {tasks.map((t) => {
                const arrow = t.hideChildren ? 'right' : 'down';
                return (
                    <div
                        className={styles.taskListTableRow}
                        style={{ height: rowHeight }}
                        key={`${t.id}row`}
                    >
                        <div
                            className={styles.taskListCell}
                            style={{ maxWidth: rowWidth, minWidth: rowWidth }}
                        >
                            <div className={styles.taskListNameWrapper}>
                                <div
                                    className={styles.taskListExpander}
                                    onClick={() => onExpanderClick(t)}
                                >
                                    {t.type === 'project' && (
                                        <AiFillCaretRight
                                            className={cs(styles.arrowButton, {
                                                [styles.arrowButtonDown]: arrow === 'down'
                                            })}
                                        />
                                    )}
                                </div>
                                <p
                                    id={t.id}
                                    onMouseEnter={handleMouseEnterItem}
                                    onMouseLeave={handleMouseLeaveItem}
                                    className={cs(styles.projectTaskName, {
                                        [styles.taskName]: t.type === 'task'
                                    })}
                                >
                                    {truncate(t.name)}
                                    {hoverItem === t.id && (
                                        <Typography
                                            body
                                            level={4}
                                            semibold
                                            className={styles.hoverContainer}
                                        >
                                            {t.name}
                                        </Typography>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const RenderLegend = ({ item }: { item: progressionListProps }) => {
    return (
        <li className={styles.item}>
            <span
                className={styles.colorBox}
                style={{
                    backgroundColor: item.color
                }}
            />
            <Typography body level={3} className={styles.itemTitle}>
                {item.name}
            </Typography>
        </li>
    );
};

const GanttChart = () => {
    const [view, setView] = useState<ViewMode>(ViewMode.Month);
    const [tasks, setTasks] = useState<Task[]>([]);
    const [columnWidth, setColumnWidth] = useState<number>(130);
    const { width }: { width?: number } = useSize();

    const resultActivityList: ResultActivityListProps[] = useResultActivity();
    const task: TaskType = useMemo(() => 'task', []);
    const project: TaskType = useMemo(() => 'project', []);

    const progressionList = useMemo(
        () => [
            { name: 'Completed', color: '#59BA47', value: 'completed' },
            { name: 'In-progress', color: '#4285F4', value: 'in_progress' },
            { name: 'Upcoming', color: '#F49E4C', value: 'upcoming' },
            { name: 'Overdue', color: '#EE402D', value: 'overdue' }
        ],
        []
    );

    const handleExpanderClick = useCallback(
        (task: Task) => {
            setTasks(tasks.map((t: Task) => (t.id === task.id ? task : t)));
        },
        [tasks]
    );

    const handleDayViewMode = useCallback(() => {
        setView(ViewMode.Day);
    }, []);

    const handleWeekViewMode = useCallback(() => {
        setView(ViewMode.Week);
    }, []);

    const handleMonthViewMode = useCallback(() => {
        setView(ViewMode.Month);
    }, []);

    const chooseColor = useCallback(
        (progression: string) => {
            return progressionList.find((item) => item.value === progression)?.color;
        },
        [progressionList]
    );

    useEffect(() => {
        if (view === ViewMode.Month) {
            setColumnWidth(130);
        } else if (view === ViewMode.Day) {
            setColumnWidth(90);
        } else setColumnWidth(110);
    }, [view]);

    const listWidth = useMemo(() => {
        if (width) {
            if (width < 512) return '75px';
            else if (width < 992) return '220px';
            else return '350px';
        }
    }, [width]);

    useEffect(() => {
        const taskNew = resultActivityList?.map((el: ResultActivityListProps, index: number) => {
            if (el.subActivities.length > 0) {
                const startDate = Math.min(
                    ...el.subActivities
                        .filter((e) => e.dateFrom)
                        .map((el) => +new Date(el.dateFrom))
                );
                const endDate = Math.max(
                    ...el.subActivities.filter((e) => e.dateTo).map((el) => +new Date(el.dateTo))
                );
                const isNotCompleted = el.subActivities.some(
                    (el: Activity) => el.status !== 'completed'
                );

                const taskResult = {
                    start: new Date(startDate),
                    end: new Date(endDate),
                    name: el.result,
                    id: index.toString(),
                    progress: 0,
                    type: project,
                    hideChildren: true,
                    isDisabled: true,
                    styles: {
                        backgroundColor: isNotCompleted ? '#B5D5F5' : '#4285F4',
                        backgroundSelectedColor: isNotCompleted ? '#B5D5F5' : '#4285F4'
                    }
                };

                const taskTask = el.subActivities?.map((e: Activity) => {
                    return {
                        start: new Date(e.dateFrom),
                        end: new Date(e.dateTo),
                        name: e.name,
                        id: e.id.toString(),
                        progress: 0,
                        type: task,
                        project: index.toString(),
                        isDisabled: true,
                        styles: {
                            backgroundColor: chooseColor(e.status),
                            backgroundSelectedColor: chooseColor(e.status)
                        }
                    };
                });
                return [taskResult, ...taskTask.flat()];
            } else return [];
        });
        taskNew && setTasks(taskNew.flat());
    }, [resultActivityList, chooseColor, project, task]);

    const renderTooltip = useCallback(() => <></>, []);

    return (
        <div className={styles.container}>
            <DashboardHeader title="Gantt Chart" />
            <div className={styles.ganttContainer}>
                <div className={styles.titleSection}>
                    <Typography body level={1} semibold>
                        Results
                    </Typography>
                    <div className={styles.viewMode}>
                        <Button
                            onClick={handleDayViewMode}
                            className={cs(styles.viewButton, styles.leftButton, {
                                [styles.buttonClicked]: view === ViewMode.Day
                            })}
                            subtle
                            small
                            leftIcon={view === ViewMode.Day ? MdDone : undefined}
                        >
                            Day
                        </Button>
                        <Button
                            onClick={handleWeekViewMode}
                            className={cs(styles.viewButton, styles.middleButton, {
                                [styles.buttonClicked]: view === ViewMode.Week
                            })}
                            subtle
                            small
                            leftIcon={view === ViewMode.Week ? MdDone : undefined}
                        >
                            Week
                        </Button>
                        <Button
                            onClick={handleMonthViewMode}
                            className={cs(styles.viewButton, styles.rightButton, {
                                [styles.buttonClicked]: view === ViewMode.Month
                            })}
                            subtle
                            small
                            leftIcon={view === ViewMode.Month ? MdDone : undefined}
                        >
                            Month
                        </Button>
                    </div>
                </div>
                <div>
                    {tasks.length > 0 && (
                        <Gantt
                            tasks={tasks}
                            viewMode={view}
                            barFill={50}
                            headerHeight={60}
                            onExpanderClick={handleExpanderClick}
                            listCellWidth={listWidth}
                            columnWidth={columnWidth}
                            rowHeight={64}
                            fontSize="16px"
                            fontFamily="Inter"
                            TooltipContent={renderTooltip}
                            barCornerRadius={4}
                            TaskListHeader={CreateTaskListHeader}
                            TaskListTable={CreateTaskListTable}
                        />
                    )}
                </div>
                <ul className={styles.legend}>
                    {progressionList.map((el) => (
                        <RenderLegend item={el} key={el.name} />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default GanttChart;
