import { useMemo, useCallback } from 'react';
import { PieChart, Pie, Legend, Cell, ResponsiveContainer, Tooltip } from 'recharts';

import Typography from 'components/Typography';
import PieChartLegend from 'components/PieChartLegend';

import styles from './styles.scss';

const RADIAN = Math.PI / 180;

type CustomizedShapeProps = {
    cx: number;
    cy: number;
    midAngle: number;
    percent: number;
    index: number;
    innerRadius: number;
    outerRadius: number;
};

const AgeGroupCount = ({ data }: { data: any }) => {
    const pieChartData = useMemo(
        () => [
            {
                name: '0-59 Months',
                count: data?.age0to59monthsSum,
                color: '#006eb5'
            },
            {
                name: '5-17 Years',
                count: data?.age5to17yrsSum,
                color: '#c90076'
            },
            {
                name: '18-49 Years',
                count: data?.age18to49yrsSum,
                color: '#f49e4c'
            },
            {
                name: '50 Years or more',
                count: data?.age50yrsPlusSum,
                color: '#59ba47'
            }
        ],
        [data]
    );

    const renderCustomizedLabel = useCallback((labelProps: CustomizedShapeProps) => {
        const { cx, cy, midAngle, percent, innerRadius, outerRadius } = labelProps;

        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill="#fff"
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
                fontWeight={600}
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    }, []);

    return (
        <div className={styles.pieContainer}>
            <Typography body level={2} semibold className={styles.pieTitle}>
                Age-wise beneficiaries
            </Typography>
            <ResponsiveContainer height="85%">
                <PieChart>
                    <Pie
                        data={pieChartData}
                        dataKey="count"
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={100}
                        stroke="#f4f4f4"
                    >
                        {pieChartData?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend content={<PieChartLegend />} />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default AgeGroupCount;
