export const resultBeneficiaries = [
    {
        id: 0,
        Individual: '78,303',
        'Houses RVA': '4,000',
        'Indirect Beneficiaries': '8,545,057',
        'Houses Retrofitted': 8,
        'Mitigation Measure': 24,
        Organization: 44
    },
    {
        id: 1,
        result: 'Shared understanding/ anticipation for managing earthquake and urban risks',
        Individual: '31,559',
        'Houses RVA': '4,000',
        Organization: 44
    },
    {
        id: 2,
        result: 'Systems Strengthening at Provincial and Local levels (Coordination Mechanisms, Plans)',
        Individual: '45,084',
        Organization: 44
    },
    {
        id: 3,
        result: 'Clusters-specific Preparedness, and Improved coordination and interlinkages between province, local levels and humanitarian stakeholders.',
        'Indirect Beneficiaries': '8,545,057',
        Organization: 9
    },
    {
        id: 4,
        result: 'Demonstrating specific measures for reducing disaster risk in urban municipalities and provinces through enhanced preparedness.',
        Individual: '1,600',
        'Houses Retrofitted': 8,
        'Mitigation Measure': 24,
        Organization: 19
    },
    {
        id: 5,
        result: 'Advocacy and Policy Engagement for enhancing humanitarian preparedness and reducing disaster risk.',
        Individual: 60,
        Organization: 44
    }
];
