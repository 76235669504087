import gonLogo from 'assets/images/gon.svg';
import euLogo from 'assets/images/eu-logo.svg';
import unWomenLogo from 'assets/images/un-women.svg';
import undpLogo from 'assets/images/undp.svg';
import unicefLogo from 'assets/images/unicef.png';

import styles from './styles.scss';

const PartnersLogo = () => {
    return (
        <div className={styles.container}>
            <div className={styles.supported}>
                <div className={styles.logoWrapper}>
                    <img className={styles.logo} src={gonLogo} alt="gon" />
                </div>
            </div>
            <div className={styles.funded}>
                <div className={styles.logoWrapper}>
                    <img className={styles.logo} src={euLogo} alt="eu" />
                </div>
            </div>
            <div className={styles.partnersWrapper}>
                <div className={styles.partnerLogoWrapper}>
                    <img className={styles.partnerLogo} src={undpLogo} alt="undp" />
                    <img className={styles.unicefLogo} src={unicefLogo} alt="unicef" />
                    <img className={styles.unWomenLogo} src={unWomenLogo} alt="un women" />
                </div>
            </div>
        </div>
    );
};

export default PartnersLogo;
