import { BiFile } from 'react-icons/bi';

import Typography from 'components/Typography';

import styles from './styles.scss';
import cs from '@ra/cs';

const EmptyComponent = ({ small }: { small?: boolean }) => {
    return (
        <div
            className={cs(styles.emptyWrapper, {
                [styles.small]: small
            })}
        >
            <BiFile className={styles.fileIcon} />
            <Typography body level={2}>
                Data not found !!
            </Typography>
        </div>
    );
};

export default EmptyComponent;
