import { useCallback, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import Typography from 'components/Typography';

import Popup from '@ra/components/Popup';
import useToggle from '@ra/hooks/useToggle';
import List, { KeyExtractor, ListRenderItem } from '@ra/components/List';

import { Region, Activity } from 'services/types';
import type { RootState } from 'store';
import { useAppDispatch } from 'hooks/store';
import * as RegionAction from 'store/slices/region';

import styles from './styles.scss';

const keyExtractor: KeyExtractor<Region> = (item) => item?.name;

const RegionDropdown: React.FC = () => {
    const { activity }: { activity: Activity[] } = useSelector(
        (state: RootState) => state.activity
    );
    const anchorRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();

    const { region, activeRegion }: { region: Region[]; activeRegion: Region } = useSelector(
        (state: RootState) => state.region
    );

    const regionData = useMemo(() => {
        const dt = activity.map((el) => ({
            ...el,
            regions: el?.activityRegions.flatMap((e) => e.region)
        }));
        let data = dt.flatMap((el) => el.regions);
        data = data.filter((element, i) => i === data.indexOf(element));
        const regions = region?.filter((el) => data.includes(el?.id as never));
        return regions?.filter((el) => el.name !== activeRegion.name);
    }, [activeRegion.name, activity, region]);

    const [dropdownVisible, toggleDropdownVisible] = useToggle(false);

    const handleChangeRegion = useCallback(
        (region: Region) => {
            toggleDropdownVisible();
            dispatch(RegionAction.setActiveRegion(region));
        },
        [dispatch, toggleDropdownVisible]
    );

    const renderRegionItem: ListRenderItem<Region> = useCallback(
        ({ item }) => {
            return (
                <div onClick={() => handleChangeRegion(item)} className={styles.regionItem}>
                    <Typography level={3} className={styles.regionName}>
                        {item.name}
                    </Typography>
                </div>
            );
        },
        [handleChangeRegion]
    );

    const ClearRegion = useMemo(() => {
        if (activeRegion.id) {
            return (
                <div
                    onClick={() => handleChangeRegion({ id: null, name: '' })}
                    className={styles.clearItem}
                >
                    <Typography level={3} className={styles.title}>
                        Clear Region
                    </Typography>
                </div>
            );
        }
        return null;
    }, [activeRegion, handleChangeRegion]);

    return (
        <div className={styles.dropdownWrapper}>
            <div className={styles.button} ref={anchorRef} onClick={toggleDropdownVisible}>
                <Typography body level={3} className={styles.title}>
                    {activeRegion.name || 'Region'}
                </Typography>
                {dropdownVisible ? (
                    <IoIosArrowUp color="#55606e" />
                ) : (
                    <IoIosArrowDown color="#55606e" />
                )}
            </div>
            <Popup
                isVisible={dropdownVisible}
                anchor={anchorRef}
                anchorOrigin="bottom right"
                transformOrigin="top right"
                onClose={toggleDropdownVisible}
                closeOnOutsideClick
                className={styles.dropdown}
            >
                <List
                    className={styles.regionDropdown}
                    keyExtractor={keyExtractor}
                    data={regionData}
                    renderItem={renderRegionItem}
                    component="ul"
                    FooterComponent={ClearRegion}
                />
            </Popup>
        </div>
    );
};

const DashboardHeader = ({ title }: { title: string }) => {
    return (
        <div className={styles.header}>
            <Typography heading level={4} bold>
                {title}
            </Typography>
            <RegionDropdown />
        </div>
    );
};

export default DashboardHeader;
