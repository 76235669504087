import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Activity, Report } from 'services/types';

interface ActivityReducerState {
    activity: Activity[];
    report: Report[];
}

const initialState: ActivityReducerState = {
    activity: [],
    report: []
};

const activitySlice = createSlice({
    name: 'activity',
    initialState,
    reducers: {
        setActivity: (state: ActivityReducerState, { payload }: PayloadAction<Activity[]>) => {
            state.activity = payload;
        },
        setReport: (state: ActivityReducerState, { payload }: PayloadAction<Report[]>) => {
            state.report = payload;
        }
    }
});

export const { setActivity, setReport } = activitySlice.actions;

export default activitySlice.reducer;
