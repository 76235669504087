import { useState, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import Typography from 'components/Typography';
import Input from 'components/Input';
import Button from 'components/Button';

import Form, { FormSubmitCallback, InputField } from '@ra/components/Form';

import Api from 'services/api';
import usePromise from '@ra/hooks/usePromise';
import { useAppDispatch } from 'hooks/store';

import * as authActions from 'store/slices/auth';

import gonLogo from 'assets/images/gon.svg';
import euLogo from 'assets/images/eu-logo.svg';
import unWomenLogo from 'assets/images/un-women.svg';
import undpLogo from 'assets/images/undp.svg';
import unicefLogo from 'assets/images/unicef.png';

import styles from './styles.scss';

const LoginForm = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [{ loading }, login] = usePromise(Api.login);

    const [error, setError] = useState<any>(null);

    const handleLogin: FormSubmitCallback = useCallback(
        async (formData) => {
            try {
                const { access, refresh } = await login(formData);
                dispatch(authActions.setToken(access));
                dispatch(authActions.setRefreshToken(refresh));
                dispatch(authActions.login());
                Api.loadUserToStore();
                navigate('/');
            } catch (err) {
                console.log(err);
                setError(err);
            }
        },
        [login, dispatch, navigate]
    );

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <img className={styles.logo} src={gonLogo} alt="gon" />
                <img className={styles.logo} src={euLogo} alt="eu" />
            </div>
            <Form
                formErrorClassName={styles.errorMessage}
                error={error}
                onSubmit={handleLogin}
                className={styles.formWrapper}
            >
                <Typography heading level={3} semibold className={styles.heading}>
                    Log in
                </Typography>
                <Typography body level={3} semibold className={styles.subHeading}>
                    Please enter your details.
                </Typography>
                <div className={styles.inputWrapper}>
                    <InputField
                        className={styles.input}
                        component={Input}
                        name="username"
                        label="Email"
                        labelClassName={styles.label}
                        placeholder="Enter your email"
                        required
                    />
                    <InputField
                        className={styles.input}
                        component={Input}
                        name="password"
                        type="password"
                        label="Password"
                        labelClassName={styles.label}
                        placeholder="Enter your password"
                        required
                    />
                </div>
                <Link to="/forgot-password" className={styles.msgWrapper}>
                    <Typography body level={5} className={styles.forgotLink}>
                        Forgot Password?
                    </Typography>
                </Link>
                <Button loading={loading} type="submit" className={styles.authButton}>
                    <Typography body level={3} semibold>
                        Log in
                    </Typography>
                </Button>
            </Form>
            <div className={styles.partnersWrapper}>
                <img className={styles.partnerLogo} src={undpLogo} alt="undp" />
                <img className={styles.unicefLogo} src={unicefLogo} alt="unicef" />
                <img className={styles.unWomenLogo} src={unWomenLogo} alt="un women" />
            </div>
        </div>
    );
};

export default LoginForm;
