import React, { useCallback } from 'react';
import Icon from 'react-eva-icons';

import Input, { InputProps } from '@ra/components/Form/Input';

import cs from '@ra/cs';

import styles from './styles.scss';

interface CustomInputProps extends InputProps {
    leftIcon?: string;
    rightIcon?: string;
    textClassName?: string;
    containerClassName?: string;
    clearable?: boolean;
    onClear?: React.MouseEventHandler;
}

const _Input: React.FC<CustomInputProps> = (props) => {
    const {
        containerClassName,
        className,
        errorMessage,
        leftIcon,
        rightIcon,
        clearable,
        onClear,
        disabled,
        inputRef = React.createRef(),
        ...otherProps
    } = props;

    const handleClear = useCallback(
        (e: React.MouseEvent) => {
            if (disabled || !clearable) {
                return;
            }
            if (inputRef?.current) {
                inputRef.current.value = '';
                inputRef.current.focus?.();
            }
            onClear?.(e);
        },
        [onClear, disabled, inputRef, clearable]
    );

    return (
        <div className={cs(styles.container, containerClassName)}>
            <Input
                className={cs(styles.input, className, {
                    [styles.inputLeftPadded]: !!leftIcon,
                    [styles.inputRightPadded]: !!rightIcon
                })}
                textClassName={styles.inputText}
                errorMessage={errorMessage}
                inputRef={inputRef}
                disabled={disabled}
                {...otherProps}
            />
            {!!leftIcon && (
                <span className={cs(styles.icon, styles.iconLeft)}>
                    <Icon name={leftIcon || 'search'} size="medium" fill="#757B83" />
                </span>
            )}
            {(clearable || rightIcon) && (
                <span
                    onClick={handleClear}
                    className={cs(styles.icon, styles.iconRight, {
                        [styles.iconError]: !!errorMessage,
                        [styles.iconClickable]: clearable && !disabled
                    })}
                >
                    <Icon name={rightIcon || 'close'} size="large" fill="#757B83" />
                </span>
            )}
        </div>
    );
};

export default _Input;
