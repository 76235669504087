import Typography from 'components/Typography';

import dotIcon from 'assets/icons/dotted.svg';
import dashboardImage from 'assets/images/dashboard.png';

import styles from './styles.scss';

const ProjectInfo = () => {
    return (
        <section className={styles.container}>
            <div>
                <Typography heading level={3} bold className={styles.heading}>
                    Strengthening Urban Preparedness, Earthquake Preparedness and Response in
                    Western Regions of Nepal (SUPER)
                </Typography>
                <img src={dotIcon} className={styles.dottedIcon} alt="dot" />
            </div>
            <div className={styles.imageWrapper}>
                <img src={dashboardImage} className={styles.dashboardImage} alt="dashboard" />
            </div>
        </section>
    );
};

export default ProjectInfo;
