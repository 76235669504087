import { useCallback } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Toast from 'components/Toast';

import store, { persistor } from 'store';
import { initializeData } from 'services/bootstrap';
import router from 'routes';

import 'styles/_base.scss';

function App() {
    const handleInitialize = useCallback(async () => {
        await initializeData();
    }, []);
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor} onBeforeLift={handleInitialize}>
                <RouterProvider router={router} />
            </PersistGate>
            <Toast />
        </Provider>
    );
}

export default App;
