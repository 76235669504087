import { useState, useCallback, useMemo, useEffect } from 'react';
import Icon from 'react-eva-icons';
import { useSelector } from 'react-redux';

import DashboardHeader from 'components/DashboardHeader';
import ActionItem from 'components/ActionsItem';
import Typography from 'components/Typography';
import EmptyComponent from 'components/EmptyComponent';

import cs from '@ra/cs';
import List, { KeyExtractor, ListRenderItem } from '@ra/components/List';
import Pagination, { PageChangeCallback } from '@ra/components/Pagination';
import usePromise from '@ra/hooks/usePromise';

import Api from 'services/api';
import { Region, Actions, PrivateUser } from 'services/types';
import type { RootState } from 'store';

import styles from './styles.scss';

const keyExtractor: KeyExtractor<Actions> = (item) => item.id;

const RecentActions = () => {
    const { activeRegion }: { activeRegion: Region } = useSelector(
        (state: RootState) => state.region
    );

    const [{ loading, result }, getNotification] = usePromise(Api.getNotification);

    useEffect(() => {
        getNotification();
    }, [getNotification]);

    const [usersInfo, setUserInfo] = useState<PrivateUser[]>();

    const notifications = useMemo(() => {
        const data = result?.results.map((el: Actions) => ({
            ...el,
            actorInfo: usersInfo?.find((e) => e.username === el.actorObjStr)
        }));
        if (activeRegion?.name) {
            return data?.filter((el: Actions) =>
                el.actionObjectObjStr
                    ?.toLocaleLowerCase()
                    .includes(activeRegion?.name?.toLocaleLowerCase())
            );
        }
        return data;
    }, [activeRegion, result?.results, usersInfo]);

    const getUsers = useCallback(async () => {
        try {
            const searchValue = new Set(result?.results.map((el: Actions) => el.actorObjStr));
            const data = await Api.getUser({ search: Array.from(searchValue).join(',') });
            setUserInfo(data?.results);
        } catch (error) {
            console.log(error);
        }
    }, [result?.results]);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    const [page, setPage] = useState<number>(1);

    const renderItems: ListRenderItem<Actions> = useCallback(({ item }) => {
        return <ActionItem item={item} />;
    }, []);

    const handlePageChange: PageChangeCallback = useCallback(({ currentPage }) => {
        setPage(currentPage);
    }, []);

    const visibleData = useMemo(() => {
        const initIndex = (page - 1) * 6;
        return notifications?.slice(initIndex, initIndex + 6);
    }, [notifications, page]);

    const renderLeftControl = useCallback(() => {
        return (
            <div className={cs(styles.paginationControlItem, styles.paginationControlItemLeft)}>
                <Icon name="arrow-ios-back" size="medium" fill="#55606E" />
                <Typography body level={5} semibold className={styles.controlTitle}>
                    Previous
                </Typography>
            </div>
        );
    }, []);

    const renderRightControl = useCallback(() => {
        return (
            <div className={cs(styles.paginationControlItem, styles.paginationControlItemRight)}>
                <Typography body level={5} semibold className={styles.controlTitle}>
                    Next
                </Typography>
                <Icon
                    className={styles.controlIcon}
                    name="arrow-ios-forward"
                    size="medium"
                    fill="#55606E"
                />
            </div>
        );
    }, []);

    return (
        <div className={styles.container}>
            <DashboardHeader title="Recent Actions" />
            <div className={styles.main}>
                <List
                    loading={loading}
                    data={visibleData}
                    renderItem={renderItems}
                    keyExtractor={keyExtractor}
                    EmptyComponent={EmptyComponent}
                    LoadingComponent={
                        <Typography body level={3}>
                            Loading ......
                        </Typography>
                    }
                />
                <div className={styles.paginationWrapper}>
                    <Pagination
                        showControlIcons
                        controlIconClassName={styles.paginationControl}
                        className={styles.pagination}
                        pageItemClassName={styles.paginationItem}
                        activePageItemClassName={styles.paginationItemActive}
                        onChange={handlePageChange}
                        totalRecords={notifications?.length}
                        pageLimit={6}
                        pageNum={page}
                        pageNeighbours={0}
                        renderLeftControl={renderLeftControl}
                        renderRightControl={renderRightControl}
                    />
                </div>
            </div>
        </div>
    );
};

export default RecentActions;
