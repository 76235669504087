import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Region } from 'services/types';

interface RegionReducerState {
    region: Region[];
    activeRegion: Region;
}

const initialState: RegionReducerState = {
    region: [],
    activeRegion: {
        id: null,
        name: ''
    }
};

const RegionSlice = createSlice({
    name: 'Region',
    initialState,
    reducers: {
        setRegion: (state: RegionReducerState, { payload }: PayloadAction<Region[]>) => {
            state.region = payload;
        },
        setActiveRegion: (state: RegionReducerState, { payload }: PayloadAction<Region>) => {
            state.activeRegion = payload;
        }
    }
});

export const { setRegion, setActiveRegion } = RegionSlice.actions;

export default RegionSlice.reducer;
