import RequestBuilder from '@ra/services/request';

import store from 'store';

import * as authActions from 'store/slices/auth';

export const dispatch = store.dispatch;

type QueryType = {
    [key: string]: string | number;
};

const TokenInterceptor = (req: Request) => {
    const {
        auth: { token }
    } = store.getState();
    if (token && !req.url.includes('jwt/refresh')) {
        req.headers.append('Authorization', `Bearer ${token}`);
    }
};

const RefreshTokenInterceptor = async (res: Response) => {
    const {
        auth: { isAuthenticated, refreshToken }
    } = store.getState();
    if (isAuthenticated && refreshToken && res.status === 403) {
        await ApiService.refreshToken(refreshToken);
    }
};

const apiVersion = process.env.REACT_APP_API_VERSION || 'v1';
export const request = new RequestBuilder(process.env.REACT_APP_API_BASE_URL)
    .setRequestInterceptors([TokenInterceptor, console.log])
    .setResponseInterceptors([RefreshTokenInterceptor, console.log])
    .setRetryConfig({ backoffFactor: 0, maxRetries: 2 })
    .build();

class Api {
    async get(url: string, options?: any) {
        const { error, data, response } = await request(`/api/${apiVersion}${url}`, options);
        if (error) {
            if (response.status === 500) {
                throw new Error('500 Internal Server Error');
            }
            console.log(data);
            throw data || 'Request Error';
        }
        return data;
    }

    async post(url: string, body?: any, options?: any) {
        const headers = options?.headers || {
            'content-type': 'application/json'
        };
        const { error, data, response } = await request(`/api/${apiVersion}${url}`, {
            method: 'POST',
            headers,
            body: options?.headers ? body : JSON.stringify(body)
        });
        if (error) {
            if (response.status === 500) {
                throw new Error('500 Internal Server Error');
            }
            console.log(data);
            throw data || 'Request Error';
        }
        return data;
    }

    async patch(url: string, body?: any, options?: any) {
        const headers = options?.headers || {
            'content-type': 'application/json'
        };
        const { error, data, response } = await request(`/api/${apiVersion}${url}`, {
            method: 'PATCH',
            headers,
            body: options?.headers ? body : JSON.stringify(body)
        });
        if (error) {
            if (response.status === 500) {
                throw new Error('500 Internal Server Error');
            }
            console.log(data);
            throw data || 'Request Error';
        }
        return data;
    }

    async put(url: string, body?: any) {
        const { error, data, response } = await request(`/api/${apiVersion}${url}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        if (error) {
            if (response.status === 500) {
                throw new Error('500 Internal Server Error');
            }
            console.log(data);
            throw data || 'Request Error';
        }
        return data;
    }

    async delete(url: string) {
        const { error, data, response } = await request(`/api/${apiVersion}${url}`, {
            method: 'DELETE'
        });
        if (error) {
            if (response.status === 500) {
                throw new Error('500 Internal Server Error');
            }
            console.log(data);
            throw data || 'Request Error';
        }
        return data;
    }

    async refreshToken(refreshToken: string) {
        try {
            const data = await this.post('/jwt/refresh/', {
                refresh: refreshToken
            });
            if (data?.access) {
                return dispatch(authActions.setToken(data.access));
            }
            dispatch(authActions.logout());
        } catch (error) {
            dispatch(authActions.logout());
            console.log(error);
        }
    }

    login = (body: any) => {
        return this.post('/jwt/create/', body);
    };

    changePassword = (body: any) => {
        return this.post('/user/me/change_password/', body);
    };

    forgotPassword = (body: any) => {
        return this.post('/user/password_reset/', body);
    };

    passwordResetVerify = (body: any) => {
        return this.post('/user/password_reset/verify/', body);
    };

    resetPassword = (body: any) => {
        return this.post('/user/password_reset/change/', body);
    };

    updateActivityStatus = (body: any, activityId: number) => {
        return this.patch(`/activity-region/${activityId}`, body);
    };

    addDeliverable = (body: any, options?: any) => {
        return this.post('/report/', body, options);
    };

    loadUserToStore = async () => {
        try {
            const user = await this.get('/user/me/');
            if (user) {
                dispatch(authActions.setUser(user));
            }
        } catch (error) {
            console.log(error);
        }
    };

    updateUser = (body: any) => {
        return this.patch('/user/me/', body);
    };

    getUser = (query: QueryType) => {
        return this.get('/user/', { query });
    };

    getActivity = (query: QueryType) => {
        return this.get('/activity/', { query });
    };

    getReport = (query: QueryType) => {
        return this.get('/report/', { query });
    };

    getResource = (query: QueryType) => {
        return this.get('/resource/', { query });
    };

    getResult = () => {
        return this.get('/result/');
    };

    getEpic = () => {
        return this.get('/epic/');
    };

    getRegion = () => {
        return this.get('/region/');
    };

    getNotification = () => {
        return this.get('/notification/');
    };

    getBeneficiariesCount = (query: QueryType) => {
        return this.get('/report/beneficiaries_count/', { query });
    };
}

const ApiService = new Api();
export default ApiService;
