import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'store';

const selectActivity = (state: RootState) => state.activity.activity;
const activeRegion = (state: RootState) => state.region.activeRegion;

export const regionActivity = createSelector(
    [selectActivity, activeRegion],
    (activity, activeRegion) => {
        let data = activity.map((el) => {
            const statusData = el?.activityRegions.flatMap((e) => e.status);
            const dateFromList = el?.activityRegions.flatMap((e) => e.dateFrom);
            const dateToList = el?.activityRegions.flatMap((e) => e.dateTo);
            const dateFrom = new Date(Math.min(...dateFromList.map(Date.parse)))
                .toJSON()
                ?.slice(0, 10);
            const dateTo = new Date(Math.max(...dateToList.map(Date.parse))).toJSON()?.slice(0, 10);

            const activityStatus = statusData.includes('upcoming')
                ? 'upcoming'
                : statusData.includes('overdue')
                ? 'overdue'
                : statusData.includes('in_progress')
                ? 'in_progress'
                : statusData.includes('completed')
                ? 'completed'
                : 'upcoming';

            return {
                ...el,
                regions: el?.activityRegions.flatMap((e) => e.region),
                status: activityStatus,
                dateFrom: dateFrom,
                dateTo: dateTo
            };
        });
        data = data?.sort((a, b) => (b.name > a.name ? -1 : 1));
        if (activeRegion.id) {
            return data.filter((el) => el?.regions?.includes(activeRegion.id as never));
        }
        return data;
    }
);
