import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EpicType } from 'services/types';

interface EpicReducerState {
    epic: EpicType[];
}

const initialState: EpicReducerState = {
    epic: []
};

const epicSlice = createSlice({
    name: 'epic',
    initialState,
    reducers: {
        setEpic: (state: EpicReducerState, { payload }: PayloadAction<EpicType[]>) => {
            state.epic = payload;
        }
    }
});

export const { setEpic } = epicSlice.actions;

export default epicSlice.reducer;
