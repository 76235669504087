import React, { useRef, useCallback, useMemo } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdOutlineLogout, MdSettings } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import Typography from 'components/Typography';

import Popup from '@ra/components/Popup';
import useToggle from '@ra/hooks/useToggle';

import { useAppSelector, useAppDispatch } from 'hooks/store';
import { logout } from 'store/slices/auth';

import styles from './styles.scss';

const UserDropdown: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { user } = useAppSelector((state) => state.auth);
    const [dropdownVisible, toggleDropdownVisible] = useToggle(false);

    const logRef = useRef<HTMLInputElement>(null);

    const nameValue = useMemo(() => {
        if (user.firstName) {
            return `${user.firstName} ${user.lastName || ''}`;
        }
        return user.username;
    }, [user]);

    const handleLogout = useCallback(() => {
        toggleDropdownVisible();
        dispatch(logout());
    }, [dispatch, toggleDropdownVisible]);

    const onClickSettings = useCallback(() => {
        toggleDropdownVisible();
        navigate('/settings');
    }, [navigate, toggleDropdownVisible]);

    return (
        <>
            <div ref={logRef} className={styles.profileContent} onClick={toggleDropdownVisible}>
                <div className={styles.userName}>
                    <Typography body level={3} semibold className={styles.firstLetter}>
                        {nameValue?.charAt(0)}
                    </Typography>
                </div>
                <Typography body level={3} semibold className={styles.name}>
                    {nameValue}
                </Typography>
                {dropdownVisible ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
            <Popup
                isVisible={dropdownVisible}
                anchor={logRef}
                anchorOrigin="top left"
                transformOrigin="bottom left"
                onClose={toggleDropdownVisible}
                closeOnOutsideClick
                className={styles.dropdown}
            >
                <div onClick={onClickSettings} className={styles.dropdownList}>
                    <MdSettings size={24} />
                    <Typography body level={3} semibold className={styles.logoutText}>
                        Account Settings
                    </Typography>
                </div>
                <div className={styles.dropdownList} onClick={handleLogout}>
                    <MdOutlineLogout size={24} />
                    <Typography body level={3} semibold className={styles.logoutText}>
                        Logout
                    </Typography>
                </div>
            </Popup>
        </>
    );
};

export default UserDropdown;
