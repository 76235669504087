import React, { useEffect, useCallback, useMemo } from 'react';
import { IconType } from 'react-icons/lib';
import { FiAlertCircle, FiAlertTriangle, FiCheckCircle, FiInfo, FiX } from 'react-icons/fi';

import cs from '@ra/cs';

import { useAppSelector, useAppDispatch } from 'hooks/store';
import * as uiActions from 'store/slices/ui';

import styles from './styles.scss';

type ItemProps = {
    iconName: IconType;
    className?: string;
};

const Icon: React.FC<ItemProps> = ({ iconName: Icon, className }) => {
    return <Icon size={20} className={className} />;
};

const Toast = () => {
    const dispatch = useAppDispatch();
    const { toast } = useAppSelector((state) => state.ui);

    const handleClose = useCallback(() => {
        dispatch(uiActions.hideToast());
    }, [dispatch]);

    useEffect(() => {
        let timer: any;
        if (toast.duration) {
            timer = setTimeout(handleClose, toast.duration * 1000);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [handleClose, toast.duration]);

    const iconName = useMemo(() => {
        switch (toast.status) {
            case 'success':
                return FiCheckCircle;
            case 'danger':
                return FiAlertCircle;
            case 'warning':
                return FiAlertTriangle;
            default:
                return FiInfo;
        }
    }, [toast]);

    if (!toast.visible) {
        return null;
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Icon className={cs(toast.status)} iconName={iconName} />
                <p className={styles.message}>{toast.message}</p>
                <div className={styles.closeContainer} onClick={handleClose}>
                    <Icon iconName={FiX} />
                </div>
            </div>
            {toast.duration && (
                <div
                    style={{ animationDuration: `${toast.duration}s` }}
                    className={styles.timerProgress}
                />
            )}
        </div>
    );
};

export default Toast;
