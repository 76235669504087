import LoginForm from './LoginForm';
import ProjectInfo from './ProjectInfo';

import styles from './styles.scss';

const Login = () => {
    return (
        <div className={styles.wrapper}>
            <LoginForm />
            <ProjectInfo />
        </div>
    );
};

export default Login;
