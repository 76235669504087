import { createBrowserRouter } from 'react-router-dom';
import { Navigate, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import Trap from '@ra/components/Trap';
import { useAppSelector } from 'hooks/store';

import Dashboard from 'containers/Dashboard';
import ErrorPage from 'containers/Error';
import Layout from 'components/Layout';
import Activities from 'containers/Activities';
import CompletedActivities from 'containers/CompletedActivities';
import RecentActions from 'containers/RecentActions';
import Resources from 'containers/Resources';
import Login from 'containers/Login';
import AuthLayout from 'components/AuthLayout';
import ForgotPassword from 'containers/ForgotPassword';
import VerifyEmail from 'containers/ForgotPassword/VerifyEmail';
import ResetPassword from 'containers/ForgotPassword/ResetPassword';
import GanttChart from 'containers/GanttChart';
import Settings from 'containers/Settings';

const logSentryError = (error: any) => Sentry.captureException(error);

const PrivateRoute: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const { isAuthenticated } = useAppSelector((state) => state.auth);

    const location = useLocation();

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location.pathname }} />;
    }

    return <>{children}</>;
};

const AuthRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { isAuthenticated } = useAppSelector((state) => state.auth);

    const location = useLocation();

    if (isAuthenticated) {
        return <Navigate to="/" state={{ from: location.pathname }} />;
    }

    return <>{children}</>;
};

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <Trap onCatchError={logSentryError}>
                <PrivateRoute>
                    <Layout />
                </PrivateRoute>
            </Trap>
        ),
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/',
                element: <Dashboard />
            },
            {
                path: 'activities',
                element: <Activities />
            },
            {
                path: 'gantt-chart',
                element: <GanttChart />
            },
            {
                path: 'activities/completed',
                element: <CompletedActivities />
            },
            {
                path: 'recent-actions',
                element: <RecentActions />
            },
            {
                path: 'resources',
                element: <Resources />
            },
            {
                path: 'settings',
                element: <Settings />
            }
        ]
    },
    {
        path: '/login',
        element: (
            <AuthRoute>
                <Login />
            </AuthRoute>
        )
    },
    {
        path: '/',
        element: <AuthLayout />,
        children: [
            {
                path: 'forgot-password',
                element: <ForgotPassword />
            },
            {
                path: 'verify-email',
                element: <VerifyEmail />
            },
            {
                path: 'reset-password',
                element: <ResetPassword />
            }
        ]
    }
]);

export default router;
