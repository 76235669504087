import Typography from 'components/Typography';

import cs from '@ra/cs';

import { Activity } from 'services/types';
import statusName from 'utils/status';

import styles from './styles.scss';

export const ActivityStatus = ({ status }: { status: string }) => {
    return (
        <div className={styles.statusHeader}>
            <Typography
                body
                level={3}
                semibold
                className={cs(styles.status, {
                    [styles.overdue]: status === 'overdue',
                    [styles.progress]: status === 'in_progress',
                    [styles.coming]: status === 'upcoming',
                    [styles.completed]: status === 'completed'
                })}
            >
                {statusName[status]}
            </Typography>
        </div>
    );
};

const ActivityCard = ({
    item,
    onClickViewDetails
}: {
    item: Activity;
    onClickViewDetails?: React.MouseEventHandler;
}) => {
    return (
        <div className={styles.activity}>
            <div onClick={onClickViewDetails}>
                <Typography body level={3} className={styles.title}>
                    {item.name}
                </Typography>
            </div>
            <div className={styles.rightContent}>
                <Typography body level={4} className={styles.date}>
                    {item.dateFrom}
                </Typography>
            </div>
        </div>
    );
};

export default ActivityCard;
