import { Link } from 'react-router-dom';

import Typography from 'components/Typography';
import Button from 'components/Button';

import { useAppSelector } from 'hooks/store';

import image from 'assets/images/404.svg';

import styles from './styles.scss';

const Page404 = () => {
    const { isAuthenticated } = useAppSelector((state) => state.auth);
    return (
        <div className={styles.main}>
            <Typography heading level={3} semibold className={styles.logo}>
                SUPER
            </Typography>
            {!isAuthenticated && (
                <Link to="/login" className={styles.loginButtonWrapper}>
                    <Button className={styles.loginButton}>Login</Button>
                </Link>
            )}
            <div className={styles.content}>
                <img src={image} alt="404" className={styles.img} />
                <h1 className={styles.heading}>Ooops! Page Not Found</h1>
                <Typography body level={1} className={styles.desc}>
                    This page doesn’t exist or was removed.
                </Typography>
                <Link to="/">
                    <Button className={styles.button}>Back to Home</Button>
                </Link>
            </div>
        </div>
    );
};

export default Page404;
