import { useState, useCallback } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

import Typography from 'components/Typography';
import Input from 'components/Input';
import Button from 'components/Button';

import Form, { FormSubmitCallback, InputField } from '@ra/components/Form';

import { useAppSelector } from 'hooks/store';
import Toast from 'services/toast';
import Api from 'services/api';

import usePromise from '@ra/hooks/usePromise';

import styles from './styles.scss';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const { isAuthenticated } = useAppSelector((state) => state.auth);

    const [{ loading }, forgotPassword] = usePromise(Api.forgotPassword);

    const [error, setError] = useState<any>(null);

    const handleSendOTP: FormSubmitCallback = useCallback(
        async (formData) => {
            try {
                const result = await forgotPassword(formData);
                if (result) {
                    Toast.show(`OTP sent to your mail: ${formData.username}`, Toast.SUCCESS);
                    navigate('/verify-email', { state: formData?.username });
                }
            } catch (err) {
                console.log(err);
                setError(err);
            }
        },
        [forgotPassword, navigate]
    );

    const handleBackClick = useCallback(
        () => navigate(isAuthenticated ? '/settings' : '/login'),
        [isAuthenticated, navigate]
    );

    return (
        <div>
            <div onClick={handleBackClick} className={styles.backButton}>
                <BiArrowBack className={styles.icon} />
                <Typography body level={3} semibold className={styles.title}>
                    Back
                </Typography>
            </div>
            <div className={styles.loginBox}>
                <Typography heading level={3} semibold className={styles.heading}>
                    Forgot password
                </Typography>
                <Typography body level={3} semibold className={styles.subHeading}>
                    Please enter your email to send OTP and rest your password.
                </Typography>
                <Form
                    formErrorClassName={styles.errorMessage}
                    error={error}
                    onSubmit={handleSendOTP}
                    className={styles.formWrapper}
                >
                    <div className={styles.inputWrapper}>
                        <InputField
                            className={styles.input}
                            component={Input}
                            name="username"
                            label="Email"
                            labelClassName={styles.label}
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <Button loading={loading} type="submit" className={styles.authButton}>
                        <Typography body level={3} semibold>
                            Next
                        </Typography>
                    </Button>
                </Form>
            </div>
        </div>
    );
};

export default ForgotPassword;
