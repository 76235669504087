import { MdDownload } from 'react-icons/md';

import Button from 'components/Button';
import Typography from 'components/Typography';

import { Resource } from 'services/types';

import styles from './styles.scss';

const ResourceCard = ({ item }: { item: Resource }) => {
    return (
        <div className={styles.card}>
            <Typography body level={2} semibold className={styles.title}>
                {item.name}
            </Typography>
            <Typography body level={3} className={styles.description}>
                {item.description}
            </Typography>
            {item.file && (
                <a href={item.file} target="_blank" rel="noreferrer">
                    <Button className={styles.downloadButton} secondary small leftIcon={MdDownload}>
                        Download
                    </Button>
                </a>
            )}
        </div>
    );
};

export default ResourceCard;
