import React, { useState, useMemo, useCallback } from 'react';

import Typography from 'components/Typography';

import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';

import { ResultCountType, ResultActivityListProps } from 'services/types';
import useResultActivity from 'hooks/useResultActivity';

import useSize from '@ra/hooks/useSize';

import styles from './styles.scss';

interface CustomizedTickProps {
    x?: number;
    y?: number;
    payload?: {
        value: string;
    };
    truncateLength: number;
}

const HoverItem = ({
    resultData,
    index
}: {
    resultData: ResultCountType[] | [];
    index: number;
}) => {
    return (
        <div style={{ position: 'absolute', top: `${index * 48 + 115}px`, left: '15px' }}>
            <Typography body level={5} semibold className={styles.content}>
                {resultData[index]?.title}
            </Typography>
        </div>
    );
};

const truncateTick = (value: string, truncateLength: number) => {
    return value.length < truncateLength ? value : value.substring(0, truncateLength) + '...';
};

const CustomYAxisTick = ({ y, payload, truncateLength }: CustomizedTickProps) => {
    return (
        <g transform={`translate(${0},${y})`}>
            <text fontSize={14} fontWeight={400} fill="#55606e">
                {payload?.value && truncateTick(payload.value, truncateLength)}
            </text>
        </g>
    );
};

const Result: React.FC = () => {
    const resultActivityList: ResultActivityListProps[] = useResultActivity();

    const [index, setIndex] = useState<number | null>(null);

    const result = useMemo(() => {
        const data = resultActivityList.map((el: ResultActivityListProps) => {
            const completedData = el.subActivities.filter((el) => el.status === 'completed').length;
            return {
                title: el.result,
                count: (completedData * 100) / el.subActivities.length
            };
        });
        return data;
    }, [resultActivityList]);

    const { width } = useSize();

    const maxYWidth = useMemo(() => {
        if (width) {
            if (width < 500) {
                return 155;
            } else if (width < 1145) {
                return 270;
            } else if (width < 1252) {
                return 155;
            } else return 270;
        }
    }, [width]);

    const truncateLength: number = useMemo(() => (maxYWidth === 155 ? 20 : 35), [maxYWidth]);

    const handleShowYaxis = useCallback((event: any) => {
        setIndex(event.index);
    }, []);

    const handleHideYaxis = useCallback(() => {
        setIndex(null);
    }, []);

    return (
        <div className={styles.container}>
            <Typography body level={2} semibold>
                Result
            </Typography>
            <ResponsiveContainer height="90%" className={styles.barChart}>
                <BarChart data={result} layout="vertical">
                    <CartesianGrid horizontal={false} />
                    <XAxis
                        type="number"
                        axisLine={false}
                        tickLine={false}
                        tickCount={6}
                        tickFormatter={(tick) => `${tick}%`}
                        fontWeight={600}
                        fontSize={12}
                    />
                    <YAxis
                        type="category"
                        width={maxYWidth}
                        axisLine={false}
                        tickLine={false}
                        dataKey="title"
                        onMouseEnter={handleShowYaxis}
                        allowDataOverflow={false}
                        onMouseLeave={handleHideYaxis}
                        tick={<CustomYAxisTick truncateLength={truncateLength} />}
                    />
                    <Bar dataKey="count" fill="#4285F4" barSize={28} radius={[0, 4, 4, 0]} />
                </BarChart>
            </ResponsiveContainer>
            {index !== null && <HoverItem resultData={result} index={index} />}
            <Typography body level={3} className={styles.barLegend}>
                Activities Completed
            </Typography>
        </div>
    );
};

export default Result;
